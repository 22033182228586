<template>
  <a-spin :spinning="isFetching">
    <div :style="{ padding: '0 20px', minHeight: '50px' }">
      <a-row :gutter="[32, 32]">
        <a-col
          v-for="battery of batteries"
          :key="battery.name"
          :span="8"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 8 }"
          :xxl="{ span: 6 }"
        >
          <BatteryInfoCard
            :currTick="currTick"
            :data="battery"
            @scheClick="scheduledBtnOnClick"
          />
        </a-col>
      </a-row>
      <BatterySchedulerModal
        @scheduled="getBatteries"
        :deviceId="deviceId"
        v-model:visible="modalVisible"
      />
    </div>
  </a-spin>
</template>

<script>
import BatteryInfoCard from '../BatteryInfoCard.vue';
import BatterySchedulerModal from '../Modals/BatterySchedulerModal.vue';
import raf from 'raf';
export default {
  name: 'BatteryTab',
  components: { BatteryInfoCard, BatterySchedulerModal },
  data() {
    return {
      tick: 0,
      rafHandle: null,
      isFetching: false,
      batteries: [],
      modalVisible: false,
      currTick: 0,
      deviceId: '',
    };
  },
  mounted() {
    this.getBatteries();
    raf(this.handleTick);
  },
  beforeUnmount() {
    this.rafHandle && raf.cancel(this.rafHandle);
  },
  methods: {
    scheduledBtnOnClick(battery) {
      this.deviceId = battery.solshareId;
      this.modalVisible = true;
    },
    /**
     * Fetch battery info data
     */
    async getBatteries() {
      this.isFetching = true;
      try {
        const { data } = await this.$http.get(
          this.$api.getBatteryInfo(this.$store.state.selectedProject.id),
        );
        this.batteries = this.handleBatteryData(data);
      } catch (e) {
        this.$message.error('Get battery info unsuccessfully');
      }
      this.isFetching = false;
    },
    handleBatteryData(data) {
      const TIME_FORMAT = 'DD/MM HH:mm';

      const batteries = [];
      data.forEach((item) => {
        const { label, id, battery, chargingSchedule } = item;
        let chargeInfo = {
          status: 'default',
          message: 'Self Usage',
          color: '#108ee9',
        };
        let chargeScheduledInfo = `Undefined`;
        let dischargeScheduledInfo = `Undefined`;
        if (chargingSchedule && 'schedule' in chargingSchedule) {
          const { charge, discharge } = chargingSchedule['schedule'];
          if (
            charge &&
            this.$moment().isBetween(
              this.$moment.unix(charge[0]),
              this.$moment.unix(charge[1]),
            )
          ) {
            chargeInfo = {
              status: 'processing',
              message: 'Charging',
              color: 'blue',
            };
          } else if (
            discharge &&
            this.$moment().isBetween(
              this.$moment.unix(discharge[0]),
              this.$moment.unix(discharge[1]),
            )
          ) {
            chargeInfo = {
              status: 'success',
              message: 'Discharge',
              color: 'green',
            };
          }

          if (charge) {
            chargeScheduledInfo = `${this.$moment
              .unix(charge[0])
              .format(TIME_FORMAT)} - ${this.$moment
              .unix(charge[1])
              .format(TIME_FORMAT)}`;
          }
          if (discharge) {
            dischargeScheduledInfo = `${this.$moment
              .unix(discharge[0])
              .format(TIME_FORMAT)} - ${this.$moment
              .unix(discharge[1])
              .format(TIME_FORMAT)}`;
          }
        }
        batteries.push({
          chargeInfo,
          chargeScheduledInfo,
          dischargeScheduledInfo,
          name: label,
          solshareId: id,
          percent: battery,
        });
      });
      batteries.sort((a, b) => a.name.localeCompare(b.name));
      return batteries;
    },

    /**
     * The battery info will be automatically refreshing every minute
     */
    handleTick() {
      this.tick++;
      if (this.tick >= 3600) {
        this.tick = 0;
        this.getBatteries();
      }
      if (this.tick % 100 === 0) {
        this.currTick = this.tick;
      }
      this.rafHandle = raf(this.handleTick);
    },
  },
};
</script>

<style scoped></style>
