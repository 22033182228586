<template>
  <div class="consumer-info-panel box-shadow">
    <a-spin :spinning="loading">
      <a-row :gutter="[20, 20]">
        <a-col
          :span="8"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 8 }"
        >
          <div class="consumer-info-panel__desc">Property</div>
          <h2 v-if="properties.length === 1">
            {{ selectedPropertyName }}
          </h2>
          <a-select
            v-else
            :style="{ width: '100%' }"
            :placeholder="'Select a property'"
            :value="selectedPropertyName"
            @change="handleSelectChange"
          >
            <a-select-option
              v-for="property in properties"
              :key="property.id"
              :value="property.address1"
            ></a-select-option>
          </a-select>
        </a-col>
        <a-col
          :span="8"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 10 }"
        >
          <div class="consumer-info-panel__desc">
            Please select a period to view details
          </div>
          <a-range-picker
            :style="{ maxWidth: 500 }"
            :allowClear="false"
            :defaultValue="rangeDates"
            format="DD/MM/YYYY"
            :disabledDate="disabledDate"
            @change="datePickerOnChange"
          />
          <a-button
            type="primary"
            class="consumer-info-panel__apply"
            @click="apply"
          >
            Apply
          </a-button>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script setup>
import moment from 'dayjs';

const props = defineProps({
  loading: Boolean,
  properties: Array,
  selectedPropertyName: String,
  datePickerOnChange: Function,
  rangeDates: Array,
  billFrom: Object,
  apply: Function,
});

const disabledDate = (current) => {
  return !(
    current < moment() &&
    props.billFrom &&
    current >= props.billFrom.startOf('day')
  );
};

const emit = defineEmits(['update:selectedProperty']);
const handleSelectChange = (value) => {
  emit('update:selectedProperty', value);
};
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.consumer-info-panel {
  height: 100%;
  background-color: #fff;
  padding: 25px 30px;

  &__desc {
    margin-bottom: 5px;
  }

  &__progress {
    .ant-progress-steps-item {
      background-color: @main-color !important;
    }
  }

  &__apply {
    margin-left: 11px;
  }
}

@media screen and (max-width: @mobile-width) {
  .consumer-info-panel {
    &__apply {
      margin-top: 10px;
      width: 100%;
      margin-left: 0px;
    }
  }
}
</style>
