/**
 * @author Wentong Liu
 */
'use strict';

import axios from 'axios';
import store from '../store/index';

axios.defaults.baseURL = '/v2';

axios.interceptors.request.use(
  (config) => {
    if (store.state.user.token) {
      config.headers.Authorization = `Bearer ${store.state.user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      switch (err.response.status) {
        case 401:
          window.location.replace('/login');
          break;
        case 403:
          store.commit('setForbidden', true);
          break;
        default:
          break;
      }
    }
    return Promise.reject(
      err.response.data.message ||
        `request failed with status ${err.response.status}`,
    );
  },
);

export default axios;
