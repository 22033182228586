<template>
  <div class="home-project-table box-shadow">
    <h3>Projects</h3>
    <div class="home-project-table__control-panel">
      <a-button
        v-if="showAdd && isAllume"
        type="primary"
        @click="() => (addProjectModalVisible = true)"
      >
        Link a Solshare
      </a-button>
    </div>
    <a-config-provider>
      <template #renderEmpty>
        <a-empty
          :image="Empty.PRESENTED_IMAGE_SIMPLE"
          description="You haven't launched a project yet"
        />
      </template>
      <a-table
        :dataSource="projects"
        :loading="isFetching"
        class="home-project-table__table"
        :customRow="
          (record) => ({
            onClick: () => projectItemOnClick(record),
          })
        "
        :rowKey="(item) => item.id.toString()"
      >
        <a-table-column key="name" title="Project" data-index="name">
          <template #default="{ record }">
            <div class="project-info-cell">
              <div>
                <p class="project-info-cell__title" data-tn="project-info-cell">
                  {{ record.name }}
                </p>
                <p class="project-info-cell__address">{{ record.address }}</p>
              </div>
            </div>
          </template>
        </a-table-column>
        <a-table-column>
          <template #default="{ record }">
            <a-tooltip
              v-if="
                record.pageSetting.subscription && !record.paymentSubscriptionId
              "
              :title="`Project not included in a subscription`"
            >
              <WarningTwoTone
                twoToneColor="red"
                :data-tn="`warning-${record.id}`"
              />
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column
          key="property_num"
          title="Number of Connections"
          data-index="property_num"
          align="center"
        >
          <template #default="{ record }">
            <a-tooltip :title="`${record._count.property} residents`">
              <span>
                <img
                  :src="BuildingIcon"
                  alt="icon"
                  width="18"
                  :style="{ marginRight: 8 }"
                />
                {{ record._count.property }}
              </span>
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column
          v-if="showLaunch"
          key="launchAt"
          title="Launch Date"
          data-index="launchAt"
        >
          <template #default="{ text: date }">
            <a-tooltip title="launched on">
              <span>{{ $moment(date).format('DD/MM/YYYY') }}</span>
            </a-tooltip>
          </template>
        </a-table-column>

        <a-table-column
          v-if="hasAccessFeeProject && !showSubscriptionUsage"
          key="revenue"
          title="Net Revenue Generated (all time)"
          data-index="totalRevenue"
          align="center"
        >
          <template #default="{ record }">
            <span v-if="record.pageSetting.financial">
              {{ getCurrencyForCountry(record.country)
              }}{{ roundTo(record.totalRevenue, 2) }}
            </span>
            <span v-else>-</span>
          </template>
        </a-table-column>

        <a-table-column
          v-if="hasAccessFeeProject && !showSubscriptionUsage"
          key="savings"
          title="Total Resident Savings"
          data-index="totalSavings"
          align="center"
        >
          <template #default="{ record }">
            <span v-if="record.pageSetting.financial">
              {{ getCurrencyForCountry(record.country)
              }}{{ roundTo(record.totalSavings, 2) }}
            </span>
            <span v-else>-</span>
          </template>
        </a-table-column>

        <a-table-column
          v-if="showSubscriptionUsage"
          key="subscribed"
          title="Subscribed"
          data-index="subscribed"
        >
          <template #default="{ record }">
            <a-tooltip title="included in your subscription">
              <span>
                <a-switch
                  :disabled="
                    !record.pageSetting.subscription ||
                    (record.paymentSubscriptionId !== null &&
                      record.paymentSubscriptionId !==
                        $store.state.user.subscriptionId)
                  "
                  :checked="
                    record.paymentSubscriptionId ===
                    $store.state.user.subscriptionId
                  "
                  @change="() => onSubscribedChange(record)"
                  :data-tn="`subscribed-${record.id}`"
                />
              </span>
            </a-tooltip>
          </template>
        </a-table-column>
      </a-table>
    </a-config-provider>
    <AddProjectModal
      v-model:visible="addProjectModalVisible"
      :refresh="refresh"
    />
    <a-button
      v-if="showSubscriptionUsage"
      type="primary"
      @click="saveSubscriptions"
      data-tn="save-subscription-change"
      :loading="isLoading"
    >
      Save
    </a-button>
  </div>
</template>

<script setup>
import BuildingIcon from '../assets/icon/ic_building.svg?url';
import http from '../http/http';
import api from '../http/api';
import { roundTo } from '../utils/round';
import { WarningTwoTone } from '@ant-design/icons-vue';
import { getCurrencyForCountry } from '../utils/constants';
import { computed, ref, onMounted } from 'vue';
import { Empty, message } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import AddProjectModal from '@/components/Modals/AddProjectModal';

const props = defineProps({
  showAdd: Boolean,
  showLaunch: Boolean,
  showSubscriptionUsage: Boolean,
});
const emit = defineEmits(['save-completed']);

const store = useStore();
const router = useRouter();

const projects = ref([]);
const isFetching = ref(true);
const isLoading = ref(false);
const addProjectModalVisible = ref(false);

const hasAccessFeeProject = computed(() =>
  projects.value.some((project) => project.pageSetting.financial),
);

onMounted(() => refresh());

const isAllume = computed(() =>
  /@allumeenergy\.com(\.au)?$/.test(store.state.user.email),
);

async function refresh() {
  isFetching.value = true;
  try {
    const { data } = await http.get(api.getMyProjectsProvider);
    projects.value = data;
    // This determines if the user should be able to see the billing tab in settings
    // Show the billing if there are subscription projects or if the user has a
    // subscription.
    // This allows the user to manage their subscription even when Allume have marked
    // a project as a non subscription project
    if (
      data.some(
        (project) =>
          project.pageSetting.subscription || store.state.user.subscriptionId,
      )
    ) {
      store.commit('setShowBilling', true);
    }
  } catch (e) {
    message.error('Get info unsuccessfully');
  }
  isFetching.value = false;
}

function projectItemOnClick(project) {
  if (props.showSubscriptionUsage) {
    return;
  }
  router.push(`/project/${project.id}/performance`);
}

async function saveSubscriptions() {
  isLoading.value = true;
  try {
    const subscribedProjects = projects.value
      .filter(
        (project) =>
          project.paymentSubscriptionId === store.state.user.subscriptionId,
      )
      .map((project) => project.id);

    await http.post(
      api.postSubscriptionUsage(store.state.user.subscriptionId),
      { data: { projects: subscribedProjects } },
    );

    message.success('Subscribed projects saved successfully');
    emit('save-completed');
  } catch (e) {
    message.warn('Something went wrong saving your subscription');
  }
  isLoading.value = false;
}

function onSubscribedChange(project) {
  project.paymentSubscriptionId =
    project.paymentSubscriptionId === store.state.user.subscriptionId
      ? null
      : store.state.user.subscriptionId;
}
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.home-project-table {
  margin-top: 20px;
  background-color: #fff;
  padding: 30px 35px 15px;

  &__table {
    :deep(.ant-table-tbody > tr) {
      cursor: pointer;
    }
  }

  &__control-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
}

.project-info-cell {
  display: flex;

  &__logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 20px;
  }

  &__title {
    font-size: 16px;
    color: #333;
  }

  &__title,
  &__address {
    margin: 0;
  }
}

@media screen and (max-width: @mobile-width) {
  .home-project-table {
    padding: 15px 20px 10px;
  }
}
</style>
