<template>
  <Card title="Assumptions / Inputs" no-footer :loading="loading">
    <a-form
      ref="formRef"
      :model="formState"
      :wrapper-col="{ xs: 16, md: 14 }"
      :rules="{
        inputTariff: { required: true },
        exportTariff: { required: true },
        solarConnectionFee: { required: true },
      }"
    >
      <a-row :gutter="[4]" justify="space-between">
        <a-col :xs="24" :sm="12" :md="8">
          <a-form-item name="inputTariff">
            <template #label>
              <a-tooltip
                title="The amount of money the residents will pay for energy from the grid."
              >
                Input tariff
              </a-tooltip>
            </template>
            <a-input-number
              stringMode
              :addon-before="currencySymbol"
              addon-after="/kWh"
              v-model:value="formState.inputTariff"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :sm="12" :md="8">
          <a-form-item name="exportTariff">
            <template #label>
              <a-tooltip
                title="The amount of money the residents will receive for energy the export to the grid."
              >
                Export tariff
              </a-tooltip>
            </template>
            <a-input-number
              stringMode
              :addon-before="currencySymbol"
              addon-after="/kWh"
              v-model:value="formState.exportTariff"
            />
          </a-form-item>
        </a-col>
        <a-col :xs="24" :sm="12" :md="8">
          <a-form-item name="solarConnectionFee">
            <template #label>
              <a-tooltip
                :title="`The ${currencySymbol} amount the asset manager needs to charge per month for the SolShare to make a return on investment.`"
              >
                Solar connection fee
              </a-tooltip>
            </template>
            <a-input-number
              stringMode
              :addon-before="currencySymbol"
              addon-after="/month"
              v-model:value="formState.solarConnectionFee"
            />
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
    <a-button type="primary" @click="saveClicked" :loading="saveLoading">
      Save
    </a-button>
  </Card>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import http from '../../../http/http';
import api from '../../../http/api';
import { getCurrencyForCountry } from '../../../utils/constants';

const props = defineProps({
  meterId: [String, Number],
  loading: Boolean,
  onConnectionFeeSaved: Function,
});

const formRef = ref();

const store = useStore();

const currencySymbol = getCurrencyForCountry(store.state.country);
const financial = computed(() => store.state.financial);
const formState = ref({
  inputTariff: financial.value.solarAccessFee.inputTariff,
  exportTariff: financial.value.solarAccessFee.exportTariff,
  solarConnectionFee: financial.value.solarAccessFee.solarConnectionFee,
});

const saveLoading = ref(false);

watch(
  () => financial.value.solarAccessFee,
  (newVal) => {
    formState.value.inputTariff = newVal.inputTariff;
    formState.value.exportTariff = newVal.exportTariff;
    formState.value.solarConnectionFee = newVal.solarConnectionFee;
  },
  { deep: true },
);

async function saveClicked() {
  try {
    await formRef.value.validateFields();
  } catch (e) {
    return;
  }
  saveLoading.value = true;
  try {
    await http.post(api.uploadSolarAccessFee(props.meterId), formState.value);
    message.success('Solar connection fee saved successfully');
    store.commit('setSolarAccessFee', [formState]);
    await props.onConnectionFeeSaved();
  } catch (e) {
    message.error('Solar connection fee saved failed');
  }
  saveLoading.value = false;
}
</script>

<style scoped></style>
