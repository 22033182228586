<template>
  <div class="alert-list-item">
    <div class="alert-list-item__left">
      <a-switch
        v-if="onSwitchChange"
        size="small"
        :loading="isSubmitting"
        :checked="isSwitchChecked"
        class="alert-list-item__switch"
        @change="(checked) => onSwitchChange(checked, item)"
      />
      <div class="alert-list-item__title">
        {{ title }}
        <slot name="title"></slot>
      </div>
      <div class="alert-list-item__desc">
        {{ desc }}
        <slot name="desc"></slot>
      </div>
    </div>
    <div>
      <EditTwoTone
        v-if="onEdit"
        class="alert-list-item__icon"
        twoToneColor="#46883D"
        @click="() => onEdit(item)"
      />
      <a-popconfirm
        v-if="onDelete"
        title="Do you confirm to delete it?"
        placement="left"
        okText="Yes"
        @confirm="() => onDelete(item)"
      >
        <DeleteTwoTone class="alert-list-item__icon" twoToneColor="#46883D" />
      </a-popconfirm>
    </div>
  </div>
</template>

<script>
import { EditTwoTone, DeleteTwoTone } from '@ant-design/icons-vue';

export default {
  name: 'AlertListItem',
  props: {
    title: String,
    desc: String,
    item: Object,
    onEdit: Function,
    onDelete: Function,
    isSwitchChecked: Boolean,
    onSwitchChange: Function,
    isSubmitting: Boolean,
  },
  components: {
    EditTwoTone,
    DeleteTwoTone,
  },
};
</script>

<style lang="less" scoped>
.alert-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 20px;

  &:not(:last-child) {
    border-bottom: 1px solid #efefef;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__switch {
    margin-right: 15px;
  }

  &__title {
    margin-right: 30px;
    font-size: 15px;
    color: #333;
    min-width: 150px;
  }

  &__desc {
    font-size: 13px;
  }

  &__icon {
    font-size: 17px;
    padding: 5px;
    margin-left: 5px;
  }
}
</style>
