<template>
  <div class="consumer-home">
    <a-row :gutter="[20, 20]">
      <a-col :span="24">
        <InfoPanel
          :loading="isFetching"
          :properties="allProperties"
          :selectedPropertyName="property ? property.address1 : ''"
          :datePickerOnChange="datePickerOnChange"
          :rangeDates="rangeDates"
          :billFrom="property ? $moment(property.billingFrom) : $moment()"
          :apply="apply"
          @update:selectedProperty="updateSelectedProperty"
        />
      </a-col>
    </a-row>
    <a-row :gutter="[20, 20]" class="consumer-home__card-container">
      <a-col
        :span="24"
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 24 }"
      >
        <CO2Card
          :value="sumOfArray(chartData.emissionReducedList)"
          :unit="chartData.emissionReducedUnit"
          :loading="isFetching"
        />
      </a-col>
      <a-col :span="6" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 6 }">
        <ProjectStatisticCard
          :loading="isFetching"
          title="Solar Consumed"
          :amount="trimDecimal(sumOfArray(chartData.solarConsumedList), 2)"
          suffix="kWh"
          :icon="ConsumedIcon"
        />
      </a-col>
      <a-col :span="6" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 6 }">
        <ProjectStatisticCard
          :loading="isFetching"
          title="Energy Demand"
          :amount="trimDecimal(sumOfArray(chartData.energyDemandList), 2)"
          suffix="kWh"
          :icon="DemandIcon"
        />
      </a-col>
      <a-col :span="6" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 6 }">
        <ProjectStatisticCard
          :loading="isFetching"
          title="Solar Delivered"
          :amount="trimDecimal(sumOfArray(chartData.solarDeliveredList), 2)"
          suffix="kWh"
          :icon="DeliveredIcon"
        />
      </a-col>
      <a-col :span="6" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 6 }">
        <ProjectStatisticCard
          :loading="isFetching"
          title="Solar Exported"
          :amount="trimDecimal(sumOfArray(chartData.solarExportedList), 2)"
          suffix="kWh"
          :icon="ExportedIcon"
        />
      </a-col>
    </a-row>
    <div class="consumer-home__chart-container box-shadow">
      <div class="consumer-home__tips">
        Click
        <img :src="LegendIcon" alt="legned" />
        symbol to toggle graphs on and off
      </div>
      <ChartBase :loading="isFetching" :option="getChartData" />
    </div>
  </div>
</template>

<script setup>
import LegendIcon from '../../assets/icon/ic_legend.svg?url';
import ExportedIcon from '../../assets/icon/ic_exported.svg?url';
import DeliveredIcon from '../../assets/icon/ic_delivered.svg?url';
import DemandIcon from '../../assets/icon/ic_demand.svg?url';
import ConsumedIcon from '../../assets/icon/ic_consumed.svg?url';
import CO2Card from '../CO2Card';
</script>

<script>
import ProjectStatisticCard from '../ProjectStatisticCard.vue';
import ChartBase from '../ChartBase.vue';
import InfoPanel from '../InfoPanel.vue';
import { trimDecimal, sumOfArray } from '../../utils/energy-util.js';
import { normalizedDateRange } from '../../utils/date';

export default {
  name: 'PerformanceTabConsumer',
  components: { ProjectStatisticCard, ChartBase, InfoPanel },
  data() {
    return {
      trimDecimal,
      sumOfArray,
      allProperties: [],
      property: undefined,
      isFetching: false,
      chartData: {
        xAxis: [],
        energyDemandList: [],
        solarConsumedList: [],
        solarDeliveredList: [],
        solarExportedList: [],
        emissionReducedList: [],
        emissionReducedUnit: 'kg',
      },
      rangeDates: [this.$moment().subtract(1, 'day'), this.$moment()],
    };
  },
  computed: {
    getChartData() {
      const {
        xAxis,
        solarConsumedList,
        energyDemandList,
        solarExportedList,
        solarDeliveredList,
      } = this.chartData;
      const energyDemandLineName = 'Total Energy Demand';
      const solarConsumedLineName = 'Solar Consumed';
      const solarExportedLineName = 'Solar Exported';
      const solarDeliveredLineName = 'Solar Delivered';
      return {
        grid: {
          left: '20px',
          right: '20px',
          bottom: '20px',
          top: '30px',
          containLabel: true,
        },
        xAxis: [
          {
            data: xAxis,
            type: 'category',
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            name: 'kWh',
            type: 'value',
          },
        ],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            minSpan: Math.min((24 / xAxis.length) * 100, 100),
            // zoomOnMouseWheel: false,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: [
            solarConsumedLineName,
            energyDemandLineName,
            solarDeliveredLineName,
            solarExportedLineName,
          ],
        },
        series: [
          {
            name: solarConsumedLineName,
            data: solarConsumedList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: energyDemandLineName,
            data: energyDemandList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: solarDeliveredLineName,
            data: solarDeliveredList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: solarExportedLineName,
            data: solarExportedList,
            type: 'line',
            areaStyle: {},
          },
        ],
      };
    },
  },
  mounted() {
    this.getProperty();
  },
  methods: {
    datePickerOnChange(dates) {
      this.rangeDates = dates;
    },
    async getProperty() {
      this.isFetching = true;
      try {
        const { data } = await this.$http.get(this.$api.getMyProjectsConsumer);
        const properties = data.properties;
        if (properties.length > 0) {
          this.allProperties = properties;
          this.property = properties[0];
          void this.getPropertySnapshot();
        } else {
          // self registered, switch to add project page
          this.$router.replace('/add-project');
        }
      } catch (e) {
        this.$message.error('Get property info unsuccessfully');
      }
      this.isFetching = false;
    },
    handleHourlySnapshots(snapshots) {
      const xAxis = [];
      const energyDemandList = [];
      const solarConsumedList = [];
      const solarDeliveredList = [];
      const solarExportedList = [];
      const emissionReducedList = [];
      snapshots.forEach((item) => {
        xAxis.push(this.$moment(item.startAt).format('DD/MM, HH:mm'));
        energyDemandList.push(trimDecimal(item.energyDemand));
        solarConsumedList.push(trimDecimal(item.solarConsumed));
        solarDeliveredList.push(trimDecimal(item.solarDelivered));
        solarExportedList.push(trimDecimal(item.solarExported));
        emissionReducedList.push(trimDecimal(item.emissionReduced));
      });
      this.isFetching = false;
      this.chartData = {
        xAxis,
        energyDemandList,
        solarConsumedList,
        solarDeliveredList,
        solarExportedList,
        emissionReducedList,
        emissionReducedUnit: snapshots?.[0]?.emissionReducedUnit ?? 'kg',
      };
    },
    async getPropertySnapshot() {
      this.isFetching = true;
      try {
        if (this.property) {
          const [from, to] = normalizedDateRange(this.rangeDates);
          const { data } = await this.$http.get(
            this.$api.getPropertyHourlySnapshots(
              this.property.id + '',
              from,
              to,
            ),
          );
          this.handleHourlySnapshots(data);
        }
      } catch (e) {
        this.$message.error(`Get energy data error: ${e.message}`);
      }
      this.isFetching = false;
    },
    apply() {
      this.getPropertySnapshot();
    },
    updateSelectedProperty(address) {
      this.property = this.allProperties.find(
        (prop) => prop.address1 === address,
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../assets/style/variables';

.consumer-home {
  &__card-container {
    margin-top: 20px;
  }
  &__chart-container {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px 30px 20px;
  }
  &__tips {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    color: #888;
  }

  .project-statistic-card {
    padding-top: 30px;
  }
}

@media screen and (max-width: @mobile-width) {
  .consumer-home {
    &__chart-container {
      padding: 20px 0 0;
    }
  }
}
</style>
