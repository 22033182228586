<template>
  <SolshareSelector
    :meter-value="meterId"
    :meter-select-on-change="onMeterChange"
    title="Site Financials"
    description="Select a Solshare to view financial performance"
    by="id"
  />

  <SolarConnectionFeeInput
    v-if="meterId"
    :loading="loadingUserInput"
    :meterId="meterId"
    :onConnectionFeeSaved="() => fetchData(meterId)"
  />

  <div v-if="meterId" class="statistic">
    <a-row :gutter="[20, 20]">
      <a-col :span="8" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }">
        <ProjectStatisticCard
          title="Monthly Revenue"
          :amount="roundTo(Number(solshareRevenue[0]), 2)"
          :loading="loadingUserInput"
          :icon="IncomeGreenIcon"
          :prefix="currencySymbol"
        />
      </a-col>
      <a-col :span="8" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }">
        <ProjectStatisticCard
          title="All Time Revenue"
          :amount="roundTo(Number(solshareRevenue[1]), 2)"
          :loading="loadingUserInput"
          :icon="IncomeBlueIcon"
          :prefix="currencySymbol"
        />
      </a-col>
      <a-col :span="8" :xs="{ span: 24 }" :sm="{ span: 12 }" :md="{ span: 8 }">
        <ProjectStatisticCard
          title="All Time Tenant Savings"
          :amount="allTimeSavings"
          :loading="loadingUserInput"
          :icon="SavingsIcon"
          :prefix="currencySymbol"
        />
      </a-col>
    </a-row>
  </div>

  <UnitFinancials
    v-if="meterId"
    :loading="loadingProperty"
    :data="propertyData"
    :onPropertyEnabledChange="onPropertyEnabledChange"
    :onPropertyEnabledSaved="onPropertyEnabledSaved"
    :disabled="togglePayload.length === 0"
  />
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { message } from 'ant-design-vue';
import { roundTo, roundToDown } from '../../../utils/round';
import http from '../../../http/http';
import api from '../../../http/api';
import SolarConnectionFeeInput from './SolarConnectionFeeInput';
import UnitFinancials from './UnitFinancials';
import SavingsIcon from '../../../assets/icon/savings.svg?url';
import IncomeBlueIcon from '../../../assets/icon/income_blue.svg?url';
import IncomeGreenIcon from '../../../assets/icon/income_green.svg?url';
import { getCurrencyForCountry } from '../../../utils/constants';

const store = useStore();

const currencySymbol = getCurrencyForCountry(store.state.country);
const meterId = ref(null);
const loadingUserInput = ref(false);
const loadingProperty = ref(false);
const propertyData = ref([]);
const solshareRevenue = ref([]);
let propertyEnabledToggle = ref({});

const allTimeSavings = computed(() =>
  roundToDown(
    propertyData.value.reduce((a, c) => a + c.netSavings, 0),
    2,
  ),
);

async function fetchData(_meterId, isUserInputLoading = false) {
  if (isUserInputLoading) {
    loadingUserInput.value = true;
  }
  loadingProperty.value = true;

  try {
    const [
      {
        data: { solarAccessFeeUserInput, properties },
      },
      { data: propertySavings },
      { data: revenue },
    ] = await Promise.all([
      http.get(api.getMeterWithProperties(_meterId)),
      http.get(api.getMeterPropertySavings(_meterId)),
      http.get(api.getMeterRevenue(_meterId)),
    ]);

    store.commit('setSolarAccessFee', solarAccessFeeUserInput);
    solshareRevenue.value = revenue;
    propertyData.value = propertySavings.map((property) => {
      const _property = properties.find(({ id }) => id === property.id);

      const hasMonthlyFee =
        Array.isArray(_property?.solarAccessFeeProperty) &&
        _property.solarAccessFeeProperty.length > 0;

      // 1 property could connect to multiple Solshare connections
      // therefore we need to sum all of them
      const monthlyFee = hasMonthlyFee
        ? roundToDown(
            _property.solarAccessFeeProperty.reduce(
              (acc, cur) => acc + Number(cur.solarConnectionFee),
              0,
            ),
            2,
          )
        : 0;

      return {
        id: property.id,
        unit: _property.address1,
        monthlyFee,
        monthlySavings: property.monthlySavings,
        netSavings: property.netSavings,
        enabled: _property?.solarAccessFeeProperty?.[0]?.enabled ?? false,
      };
    });
    propertyEnabledToggle.value = {};
  } catch (e) {
    console.error(e);
    message.error('Get solar access fee failed.');
  }

  if (isUserInputLoading) {
    loadingUserInput.value = false;
  }
  loadingProperty.value = false;
}

async function onMeterChange(_meterId) {
  meterId.value = _meterId;
  await fetchData(_meterId, true);
}

async function onPropertyEnabledChange(propertyId, enabled) {
  if (propertyEnabledToggle.value[propertyId] === undefined) {
    propertyEnabledToggle.value[propertyId] = enabled;
  } else {
    delete propertyEnabledToggle.value[propertyId];
  }
}

const togglePayload = computed(() =>
  Object.entries(propertyEnabledToggle.value).map(([id, enabled]) => ({
    propertyId: Number(id),
    enabled,
  })),
);

async function onPropertyEnabledSaved() {
  if (togglePayload.value.length === 0) {
    return;
  }
  loadingProperty.value = true;
  try {
    await http.post(
      api.togglePropertySolarAccessFeeEnabled(meterId.value),
      togglePayload.value,
    );
  } catch (e) {
    message.error(`Operation failed.`);
  }
  // refetch because solar connection fee has changed
  await fetchData(meterId.value);
}
</script>

<style lang="less" scoped>
.statistic {
  margin: 20px 0;
}
</style>
