<template>
  <a-modal
    :title="isCreate ? 'Add Alert Criterion' : 'Update Alert Criterion'"
    :destroyOnClose="true"
    v-model:visible="modalVisible"
    :maskClosable="false"
    :footer="null"
    :afterClose="afterModalClose"
  >
    <a-form
      :initial-values="{
        type: isCreate ? undefined : editAlertType,
        limit: isCreate
          ? undefined
          : editAlertType === AlertType.SolshareOffline
          ? alerts.solshareOffline?.timeLength || undefined
          : alerts.energyLimit?.threshold || undefined,
      }"
      @finish="onFinish"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 14 }"
      :model="form"
      :rules="rules"
    >
      <a-form-item
        label="Alert Type"
        name="type"
        :extra="renderTypeFieldExtraLabel"
      >
        <a-select
          v-model:value="form.type"
          showSearch
          :disabled="!isCreate"
          placeholder="Select an alert type"
        >
          <a-select-option
            :value="AlertType.BatteryTrip"
            :disabled="!!batteryTrip"
          >
            Battery Trip
          </a-select-option>
          <a-select-option
            :value="AlertType.InverterTrip"
            :disabled="!!inverterTrip"
          >
            Inverter Trip
          </a-select-option>
          <a-select-option
            :value="AlertType.SolshareOffline"
            :disabled="!!solshareOffline"
          >
            Solshare Offline
          </a-select-option>
          <a-select-option
            :value="AlertType.EnergyLimit"
            :disabled="!!energyLimit"
          >
            Energy Below Limit
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        v-if="showLimit"
        label="Limit"
        name="limit"
        :extra="renderLimitFieldExtraLabel"
      >
        <a-input v-model:value="form.limit" type="number" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 14, offset: 6 }">
        <a-button
          block
          type="primary"
          htmlType="submit"
          :loading="isSubmitting"
        >
          {{ isCreate ? 'Add' : 'Update' }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { AlertType } from '../../utils/constants';

export default {
  name: 'AlertModal',
  props: {
    alerts: Object,
    visible: Boolean,
    isCreate: Boolean,
    onSubmit: Function,
    editAlertType: String,
    isSubmitting: Boolean,
  },
  data() {
    return {
      AlertType,
      form: {
        type: undefined,
        limit: undefined,
      },
      rules: {
        type: [
          {
            required: true,
            message: 'Alert type is required.',
            trigger: 'change',
          },
        ],
        limit: [
          { required: true, message: 'Limit is required.' },
          {
            validator: this.validateLimit,
          },
        ],
      },
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },

    selectedType: {
      get() {
        return this.isCreate ? this.form.type : this.editAlertType;
      },
      set(val) {
        this.type = val;
      },
    },

    showLimit() {
      return (
        this.selectedType === AlertType.SolshareOffline ||
        this.selectedType === AlertType.EnergyLimit
      );
    },
    renderTypeFieldExtraLabel() {
      switch (this.selectedType) {
        case AlertType.BatteryTrip:
          return 'Triggered when a SolShare detects a battery trip.';
        case AlertType.InverterTrip:
          return 'Triggered when a SolShare detects an inverter trip.';
        case AlertType.SolshareOffline:
          return 'Triggered when SolShare has not been heard from for the configured time';
        case AlertType.EnergyLimit:
          return 'Triggered when a SolShare’s output is less than a threshold over a 24 hour period ';
      }
      return '';
    },
    renderLimitFieldExtraLabel() {
      if (this.selectedType === AlertType.SolshareOffline) {
        return '{3h <= x <= 48h}';
      } else if (this.selectedType === AlertType.EnergyLimit) {
        return '{0kWh <= x <= 1000kWh}';
      }
      return '';
    },
  },
  methods: {
    afterModalClose() {
      this.selectedType = '';
    },
    onFinish(values) {
      let _values = values;
      if (!this.isCreate) {
        let id = -1;
        switch (this.editAlertType) {
          case AlertType.BatteryTrip:
            id = this.alerts.batteryTrip.id;
            break;

          case AlertType.InverterTrip:
            id = this.alerts.inverterTrip.id;
            break;

          case AlertType.SolshareOffline:
            id = this.alerts.solshareOffline.id;
            break;

          case AlertType.EnergyLimit:
            id = this.alerts.energyLimit.id;
            break;
        }

        _values = Object.assign({}, values, { id });
      }
      this.onSubmit && this.onSubmit(_values);
    },
    validateLimit(rule, value) {
      const limit = Number(value);
      if (this.selectedType === AlertType.SolshareOffline) {
        if (limit < 3 || limit > 48) {
          return Promise.reject('Invalid range');
        }
        return Promise.resolve();
      } else if (this.selectedType === AlertType.EnergyLimit) {
        if (limit < 0 || limit > 1000) {
          return Promise.reject('Invalid range');
        }
        return Promise.resolve();
      }
    },
  },
};
</script>

<style scoped></style>
