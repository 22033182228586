<template>
  <header class="provider-header">
    <div class="section">
      <div class="provider-header__container">
        <router-link to="/">
          <img class="provider-header__logo" :src="logo" alt="logo" />
        </router-link>
        <div class="provider-header__right">
          <a-tooltip
            v-if="$store.state.user.isInvoicePaid === false"
            title="It appears you have an outstanding invoice for your subscription please go to Manage Subscription to resolve"
          >
            <ExclamationCircleOutlined
              class="provider-header__warning"
              @click="$router.push('/setting/Billing')"
            />
          </a-tooltip>
          <a-tooltip title="Get help from Allume">
            <QuestionCircleOutlined
              class="provider-header__icon"
              @click="$router.push('/help')"
            />
          </a-tooltip>
          <a-tooltip title="General settings">
            <SettingOutlined
              class="provider-header__icon"
              @click="$router.push('/setting')"
            />
          </a-tooltip>
          <span class="provider-header__greeting">
            {{ greetings }}
          </span>
          <a-tooltip title="Log out">
            <PoweroffOutlined
              @click="logoutClicked"
              :style="{ marginLeft: '30px', color: '#f00' }"
            />
          </a-tooltip>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { createVNode } from 'vue';
import {
  QuestionCircleOutlined,
  SettingOutlined,
  PoweroffOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue';
import { Modal } from 'ant-design-vue';

export default {
  name: 'NavHeader',
  components: {
    QuestionCircleOutlined,
    SettingOutlined,
    PoweroffOutlined,
    ExclamationCircleOutlined,
  },
  data() {
    return {};
  },
  computed: {
    logo() {
      return this.$store.getters.getLogo;
    },
    greetings() {
      return `${this.greetingTime()}, ${
        this.$store.state.user.name || 'Admin'
      } `;
    },
  },
  methods: {
    greetingTime() {
      const hour = new Date().getHours();
      if (hour >= 5 && hour < 12) {
        return 'Good morning';
      } else if (hour >= 12 && hour < 17) {
        return 'Good afternoon';
      } else if ((hour >= 17 && hour <= 23) || hour < 5) {
        return 'Good evening';
      }
    },
    logoutClicked() {
      Modal.confirm({
        title: 'Info',
        icon: createVNode(ExclamationCircleOutlined),
        content: 'Are you sure to log out?',
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: () => this.$store.dispatch('logout'),
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.provider-header {
  background-color: #fff;
  box-shadow: 0 0 13px 1px rgba(52, 52, 52, 0.1);
  margin-bottom: 20px;
  user-select: none;

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: @nav-height;
  }

  &__right {
    display: flex;
    align-items: center;
  }

  &__logo {
    height: 36px;
  }

  &__icon {
    font-size: 17px;
    margin-right: 20px;
  }

  @keyframes flashAnimation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__warning {
    animation: flashAnimation 2s infinite;
    font-size: 17px;
    margin-right: 20px;
    color: #f10606;
  }
}

@media screen and (max-width: @mobile-width) {
  .provider-header {
    &__greeting {
      display: none;
    }
  }
}
</style>
