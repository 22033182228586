<template>
  <a-modal
    :title="isCreate ? 'Add Email Recipient' : 'Update Email Recipient'"
    destroyOnClose
    v-model:visible="modalVisible"
    :maskClosable="false"
    :footer="null"
  >
    <a-form
      :model="form"
      :rules="rules"
      :initial-values="{ email: initialValue?.email || '' }"
      @finish="onFinish"
      :labelCol="{ span: 6 }"
      :wrapperCol="{ span: 14 }"
    >
      <a-form-item label="Email" name="email" validateFirst>
        <a-input v-model:value="form.email" />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 14, offset: 6 }">
        <a-button
          block
          htmlType="submit"
          :loading="isSubmitting"
          type="primary"
        >
          {{ isCreate ? 'Add' : 'Update' }}
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'EmailRecipientModal',
  props: {
    visible: Boolean,
    isSubmitting: Boolean,
    isCreate: Boolean,
    onSubmit: Function,
    // Duplication check only
    emailRecipients: Array,
    initialValue: Object,
  },

  data() {
    return {
      form: {
        email: undefined,
      },
      rules: {
        email: [
          {
            required: true,
            message: 'Email is required.',
            trigger: 'blur',
          },
          { type: 'email', message: 'email format is incorrect' },
          { validator: this.duplicateCheck },
        ],
      },
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
  },
  methods: {
    onFinish(values) {
      const _values = this.isCreate
        ? values
        : Object.assign({}, values, { id: this.initialValue.id });
      this.onSubmit && this.onSubmit(_values);
    },
    duplicateCheck(rule, value) {
      if (
        this.emailRecipients.some((alertEmail) => alertEmail.email === value)
      ) {
        return Promise.reject('The email already exists');
      }
      return Promise.resolve();
    },
  },
};
</script>

<style scoped></style>
