export function chunk(arr, num) {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / num);

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(item);

    return resultArray;
  }, []);
}

export function backendToFrontend(arr = [], is2P) {
  const template = [];
  for (let _phase = 0; _phase < (is2P ? 2 : 3); _phase++) {
    for (let _index = 0; _index < 5; _index++) {
      template.push([_phase, _index, 0]);
    }
  }
  arr
    .map(([phase, conn, weight]) => [phase, conn, weight / 100])
    .forEach(([phase, conn, weight]) => {
      template[5 * phase + conn][2] = weight;
    });

  return chunk(template, 5);
}
