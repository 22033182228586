<template>
  <div class="login">
    <Background />
    <div class="login__container">
      <img class="login__logo" :src="logo" alt="logo" />
      <div class="login__form-container">
        <h2 class="login__title">Email Verified Successfully</h2>
        <div class="login__redirect">redirecting in {{ count }} seconds...</div>
      </div>
    </div>
    <Copyright />
  </div>
</template>

<script>
import Background from '../components/Background';
import Copyright from '../components/Copyright';

export default {
  name: 'Home',
  components: { Background, Copyright },
  data() {
    return {
      count: 3,
      intervalID: null,
    };
  },
  mounted() {
    this.intervalID = setInterval(() => {
      if (this.count > 0) {
        this.count--;
      }
      if (this.count === 0) {
        this.$router.replace('/login');
      }
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.intervalID);
  },
  computed: {
    logo() {
      return this.$store.getters.getLogo;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__container {
    margin: 0 auto;
    padding-top: 100px;
    width: 390px;
    position: relative;
    z-index: 1;
  }

  &__logo {
    height: 65px;
    display: block;
    margin: 0 auto 30px;
  }

  &__title {
    text-align: center;
    margin-bottom: 30px;
    font-weight: 500;
  }

  &__redirect {
    text-align: center;
  }

  &__form-container {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 15px 35px 0 rgba(60, 66, 87, 0.1),
      0 5px 15px 0 rgba(0, 0, 0, 0.07);
    padding: 60px 50px;
  }
}
</style>
