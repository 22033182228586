<template>
  <a-modal
    title="Terms & Conditions"
    v-model:visible="modalVisible"
    :maskClosable="false"
    :closable="false"
    :bodyStyle="{ padding: 0 }"
    :width="700"
    centered
    :keyboard="false"
  >
    <template #footer>
      <a-button @click="agreeClicked" type="primary">
        I have read and agree
      </a-button>
    </template>
    <TC />
  </a-modal>
</template>

<script>
import TC from '../TC';
export default {
  name: 'TCModal',
  components: { TC },
  data() {
    return {
      modalVisible: this.$store.state.user.tc === false,
    };
  },
  methods: {
    agreeClicked() {
      this.$http.post(this.$api.tcRead);
      this.$store.commit('setTCRead');
      this.modalVisible = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
