<template>
  <div class="copyright">
    <p class="copyright__title">
      Copyright &copy; 2015 - {{ new Date().getFullYear() }} Allume Energy. All
      rights reserved.
    </p>
  </div>
</template>

<script>
export default {
  name: 'Copyright',
};
</script>

<style lang="less" scoped>
.copyright {
  position: relative;
  margin: 20px 0 10px 0;

  &__title {
    text-align: center;
    margin-bottom: 0;
    color: #666;
    font-size: 12px;
  }
}
</style>
