<template>
  <div class="card box-shadow">
    <div class="card__title">
      {{ title }}
      <slot name="title" />
      <slot name="extra" />
    </div>
    <div :class="noPadding ? 'card__children' : 'card__children-padding'">
      <a-spin v-if="loading" />
      <slot v-else />
    </div>
    <div v-if="!noFooter" class="card__footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    noFooter: Boolean,
    noPadding: Boolean,
    loading: Boolean,
  },
};
</script>

<style lang="less" scoped>
.card {
  background-color: #fff;
  border-radius: 1px;

  & + .card {
    margin-top: 20px;
  }

  &__title {
    color: rgba(0, 0, 0, 0.9);
    padding: 15px 20px;
    border-bottom: 1px solid #e8e8e8;
    background: #fafafa;
    font-size: 17px;
    border-top-left-radius: 1px;
    border-top-right-radius: 1px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__children {
    padding-bottom: 5px;
  }

  &__children-padding {
    padding: 20px;
  }

  &__footer {
    padding: 15px 20px;
    border-top: 1px solid #eee;
  }
}
</style>
