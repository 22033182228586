<template>
  <div>
    <Card title="Billing">
      <div style="background-color: #529078; padding: 20px">
        <a-row :gutter="16">
          <a-col :span="8">
            <a-card title="< 200 Connections" :bordered="false">
              <p>$6 per connection</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="201 - 500 Connections" :bordered="false">
              <p>$5 per connection</p>
            </a-card>
          </a-col>
          <a-col :span="8">
            <a-card title="> 500 Connections" :bordered="false">
              <p>$4 per connection</p>
            </a-card>
          </a-col>
        </a-row>
      </div>
      <br />
      <p v-if="$store.state.user.subscriptionId">
        <a-alert
          v-if="endsAt"
          :message="`Current subscription ends on ${$moment(endsAt).format(
            'YYYY-MM-DD hh:mm',
          )}`"
          type="warning"
        />
        <br />
        You can manage your current subscription by clicking on the manage
        subscription below
      </p>
      <p v-else>
        You currently do not have a subscription, click manage subscription
        below to create one
      </p>
      <a-form @finish="getPortalSession" :style="{ width: 400 }">
        <a-button
          :loading="isSubmitting"
          type="primary"
          htmlType="submit"
          @click="getPortalSession"
        >
          Manage Subscription
        </a-button>
      </a-form>
    </Card>
    <Card v-if="$store.state.user.subscriptionId" title="Usage">
      <div style="background-color: #529078; padding: 20px">
        <a-row :gutter="16">
          <a-col :span="12">
            <ProjectStatisticCard
              title="Current Usage"
              :amount="usage"
              :icon="ArrowUp"
              prefix=""
            />
          </a-col>
          <a-col :span="12">
            <ProjectStatisticCard
              title="Current Cost Per Connection"
              :amount="rate"
              prefix="$"
            />
          </a-col>
        </a-row>
      </div>

      <div>
        <br />
        <p>Below are the current projects that you are subscribed to.</p>

        <p>
          To add to your subscription confirm which project should be added to
          your subscription and then click save.
        </p>

        <p>
          Note, by adding a project this will increase your usage plan by the
          number of connections on that project, which will be invoiced at the
          end of the billing period.
        </p>
        <ProjectTable
          :showSubscriptionUsage="true"
          @save-completed="getSubscription"
        />
      </div>
    </Card>
  </div>
</template>

<script setup>
import ArrowUp from '../assets/icon/arrow-up-short-wide-solid.svg?url';
</script>

<script>
import Card from '../components/Card.vue';
import ProjectTable from '../components/ProjectTable.vue';

export default {
  name: 'Billing',
  components: { Card, ProjectTable },
  mounted() {
    this.getSubscription();
  },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      usage: 0,
      rate: 0,
      endsAt: null,
    };
  },
  methods: {
    /**
     * Get a portal session from our API to redirect to for handling payment info
     *
     */
    async getPortalSession() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(
          this.$api.getPaymentPortalSession,
        );
        window.location.replace(data.url);
      } catch (e) {
        if ('response' in e) {
          this.$message.error(e.response.data.message);
        }
      }
      this.loading = false;
    },
    /**
     * Get the user subscription
     *
     */
    async getSubscription() {
      this.loading = true;
      try {
        const { data } = await this.$http.get(this.$api.getSubscription);
        this.$store.commit('setSubscriptionId', data.subscriptionId);
        this.$store.commit('setSubscriptionInvoicePaid', data.isInvoicePaid);
        this.usage = data.usage;
        this.endsAt = data.endsAt;
        this.rate = data.rate;
      } catch (e) {
        if ('response' in e) {
          this.$message.error(e.response.data.message);
        }
      }
      this.loading = false;
    },
  },
};
</script>
