/**
 * @author Wentong Liu
 */
'use strict';

import axios from 'axios';

export default {
  get(url) {
    return axios.get(url);
  },

  post(url, data, option) {
    return axios.post(url, data, option);
  },

  put(url, data) {
    return axios.put(url, data);
  },

  del(url) {
    return axios.delete(url);
  },
};
