<template>
  <Card class="accept-invitation" title="Accept Invitation">
    <p class="accept-invitation__desc">
      Are you an asset manager? By clicking accept you will have access to admin
      of the entire site.
    </p>
    <a-checkbox v-model:checked="checked">
      I confirm I am the asset manager for the site
    </a-checkbox>
    <template #footer>
      <a-button
        type="primary"
        :disabled="!checked"
        @click="accept"
        :loading="loading"
      >
        Accept invitation
      </a-button>
    </template>
  </Card>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import http from '../http/http';
import api from '../http/api';
import { message } from 'ant-design-vue';
import jwt_decode from 'jwt-decode';

const store = useStore();
const route = useRoute();
const router = useRouter();
const checked = ref(false);
const loading = ref(false);
const token = route.params.token;

async function accept() {
  loading.value = true;
  try {
    const { data } = await http.post(api.addProject, { token });
    message.success('Project linked successfully');

    if (store.getters.getRole === 'consumer') {
      const { accessToken, user } = data;
      const { exp, email, role } = jwt_decode(accessToken);
      store.commit('setUser', {
        id: user.id,
        token: accessToken,
        exp,
        email,
        role,
        name: user.firstName,
        logo: user.logo,
        tc: user.tcAccepted,
        subscriptionId: user.subscriptionId,
        isInvoicePaid: user.isInvoicePaid,
      });
    }

    await router.push('/');
    document.title = 'Provider Portal | Allume Energy';
  } catch (e) {
    message.error(e);
  }
  loading.value = false;
}
</script>

<style lang="less" scoped>
.accept-invitation {
  &__desc {
    font-size: 14px;
  }

  button {
    margin-right: 20px;
  }
}
</style>
