import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import Help from '../views/Help.vue';
import Dashboard from '../views/Dashboard.vue';
import Project from '../views/Project.vue';
import Setting from '../views/Setting.vue';
import Branding from '../views/Branding.vue';
import Profile from '../views/Profile.vue';
import DeleteAccount from '../views/DeleteAccount.vue';
import ResetPassword from '../views/ResetPassword.vue';
import AccountCreated from '@/views/AccountCreated';
import AddProject from '@/views/AddProject';
import Billing from '../views/Billing.vue';
import AcceptInvitation from '@/views/AcceptInvitation.vue';

const routes = [
  {
    path: '/',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: Dashboard,
      },
      {
        path: 'add-project',
        component: AddProject,
      },
      {
        path: 'help',
        component: Help,
      },
      {
        path: 'project/:id/:tab',
        component: Project,
      },
      {
        path: 'accept-invitation/:token',
        component: AcceptInvitation,
      },
      {
        path: 'setting',
        component: Setting,
        children: [
          {
            path: 'branding',
            component: Branding,
          },
          {
            path: 'profile',
            component: Profile,
          },
          {
            path: 'billing',
            component: Billing,
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/account-created',
    component: AccountCreated,
  },
  {
    path: '/delete-account/:token',
    component: DeleteAccount,
  },
  {
    path: '/reset-password/:token',
    component: ResetPassword,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters.isLoggedIn
  ) {
    localStorage.setItem('redirectPath', to.path);
    next({ path: '/login' });
  } else {
    next();
  }
});

export default router;
