/**
 * @author Di Wang on 24/1/20.
 */

/**
 * Validate the password
 * must contain numeric and character as well as the length is between 6 and 20
 * @param password
 */
export const validateIsStrongPassword = (password) => {
  const pattern = new RegExp(
    '^(?![\\d]+$)(?![a-zA-Z]+$)(?![^\\da-zA-Z]+$).{6,20}$',
  );
  return pattern.test(password);
};

export const validateIsEmail = (email) => {
  const pattern = new RegExp(
    '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
  );
  return pattern.test(email);
};
