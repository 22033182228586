<template>
  <a-alert
    message="It looks like you don't have any Solshares linked to your
             account"
    show-icon
    type="warning"
  />
  <Card title="What's next?" class="next" no-footer>
    <div>
      <p>
        <b>Tenant</b>: If you’re a tenant in a building with a SolShare system
        and wish to see your energy data, please contact your solar installer to
        set up your account.
      </p>
      <p>
        <b>Asset Owner / Solar Installer</b>: If you’re an asset owner or solar
        installer of a solar system with SolShare, please check your email inbox
        to accept the invitation.
      </p>
    </div>
  </Card>
</template>

<script></script>

<style lang="less" scoped>
.next {
  margin-top: 20px;
}

.add-project {
  margin-top: 20px;
  height: 40px;
}
</style>
