<template>
  <div id="container">
    <div>
      <NavHeader />
      <div class="section">
        <router-view />
      </div>
    </div>
    <Copyright :style="{ textAlign: 'center', marginTop: 40 }" />
    <TCModal />
  </div>
</template>

<script>
import Copyright from '../components/Copyright.vue';
import NavHeader from '../components/Header.vue';
import TCModal from '../components/Modals/TCModal.vue';

export default {
  name: 'Home',
  components: { Copyright, NavHeader, TCModal },
};
</script>

<style scoped>
#container {
  background-color: #edf2f9;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
</style>
