<template>
  <div class="tcbox" id="tcbox" ref="tcbox">
    <h2>ALLUME ENERGY PTY LTD TERMS AND CONDITIONS</h2>
    <p><strong>TERMS AND CONDITIONS OF USE </strong></p>
    <p><strong></strong></p>
    <h1>1 About</h1>
    <h2>
      1.1 Allume Energy Pty Ltd (ACN 605 671 494) (<strong>Allume Energy</strong
      >) provides the Premises Controllers and their Occupants with:
    </h2>
    <h3>(a) the Portal; and</h3>
    <h3>(b) any other products and services we release from time to time,</h3>
    <h3>
      which allows Occupants to access, monitor and manage solar electricity
      provided through SolShare Hardware (the <strong>Services</strong>).
      Details of the Services are found on our website www.allumeenergy.com.au
      (the <strong>Website</strong>).
    </h3>
    <p><strong>Acceptance of these Terms</strong></p>
    <h2>
      1.2 You accept these terms and conditions (the <strong>Terms</strong>) by
      registering for the Services, browsing the Website, by creating an account
      or using the Portal. By using the Website or Services, browsing the
      Website, or signing up to the Services signifies that you have read,
      understood and agree to be bound by the Terms. If you do not agree with
      the Terms, you must cease use of the Website and the Services immediately.
    </h2>
    <h2>1.3 You may not use the Services and may not accept the Terms if:</h2>
    <h3>
      (a) you are not of legal age to form a binding contract with Allume
      Energy;
    </h3>
    <h3>
      (b) you do not possess the legal right and ability to enter into a legally
      binding agreement on your own behalf or on any relevant business; or
    </h3>
    <h3>
      (c) you are a person barred from receiving the Services under the laws of
      Australia or other countries including the country in which you are
      resident or from which you use the Services.
    </h3>
    <h2>
      1.4 By engaging Allume Energy, you acknowledge and understand that Allume
      Energy does not provide you with any specialist, professional or technical
      advice. For further information, please read the Terms carefully.
    </h2>
    <h2>
      1.5 Allume Energy reserves the right to review and change any of the Terms
      by updating this page at its sole discretion. When Allume Energy updates
      the Terms, it will use reasonable endeavours to provide you with notice of
      the same. Any changes to the Terms take immediate effect from the date of
      their publication. If you choose not to accept any changes to the Terms,
      your sole recourse will be to stop using the Services. Before you
      continue, we recommend you keep a copy of the Terms for your records.
    </h2>
    <h1>2 Account Set Up</h1>
    <h2>
      2.1 To access the Services, you are required to register for an account
      with Allume Energy (the
      <strong>Account</strong>). You may register for an account via the Website
      or by contacting Allume Energy directly.
    </h2>
    <h2>
      2.2 As part of the registration process or as part of your continued use
      of the Services, you will be required to provide personal information
      about yourself (such as identification, contact details, and other
      personal information).
    </h2>
    <h2>
      2.3 You understand that by supplying Allume Energy with your address,
      email address and phone number, you may receive regular emails,
      newsletters, telephone calls or SMS updates from Allume Energy to keep you
      informed about Allume Energy&rsquo;s activities. If you do not wish to
      receive updates from Allume Energy, you may contact Allume Energy at
      <a href="mailto:privacy@allumeenergy.com.au"
        >privacy@allumeenergy.com.au</a
      >.
    </h2>
    <p><strong>Privacy</strong></p>
    <h2>
      2.4 You agree and acknowledge that Allume Energy will hold and use any
      Personal Information and User Data provided in relation to the Services in
      accordance with these Terms and the privacy policy available on the
      Website.
    </h2>
    <h1>3 Identity Verification</h1>
    <h2>
      3.1 You acknowledge and agree that due to the nature of the Services,
      Allume Energy may be required to verify your identity to ensure that you
      are not using the Services in an illegal or fraudulent manner.
    </h2>
    <h2>
      3.2 You warrant that where Allume Energy advises you in writing that they
      require further verification of your identity, you will make all
      reasonable endeavours to comply with this request within seven (7) days of
      receipt of same.
    </h2>
    <h2>
      3.3 You warrant that any information that you provide pursuant to this
      clause will be true and correct to the best of your knowledge and belief
      and failure to comply with this clause will warrant an immediate
      termination of the provision of the Services to you.
    </h2>
    <p><strong>Your Obligations</strong></p>
    <h2>3.4 You agree and acknowledge that you will:</h2>
    <h3>(a) comply with all laws;</h3>
    <h3>
      (b) provide and continue to provide us with accurate and up to date
      information;
    </h3>
    <h3>(c) use the Portal in accordance with these Terms;</h3>
    <h3>
      (d) provide all necessary information, documentation and assistance
      required by us;
    </h3>
    <h3>
      (e) maintain the confidentiality of all identification and log-in
      information required by you to access and use the Portal;
    </h3>
    <h3>(f) co-operate with us as we reasonably require;</h3>
    <h3>
      (g) ensure that any devices on which you use the Portal are in good,
      up-to-date working order and operating condition; and
    </h3>
    <h3>(h) protect the Portal from unauthorised access, use or damage.</h3>
    <h2>
      3.5 You acknowledge that Allume Energy has entered into these Terms in
      reliance on the acknowledgements, representations and warranties given by
      you in these Terms.
    </h2>
    <h2>
      3.6 If you are a Premises Controller, then you warrant that you will:
    </h2>
    <h3>
      (a) provide Allume Energy written notice of all laws, regulations, and
      codes that apply to your use of User Data or the provision of the Services
      to you prior to Allume Energy providing your Occupants access to the
      Portal; and
    </h3>
    <h3>
      (b) you and your Representatives will not breach any law regarding the
      use, access or control of any User Data.
    </h3>
    <p><strong>Services</strong></p>
    <h2>
      3.7 By Allume Energy offering its Service to you, you agree and
      acknowledge that:
    </h2>
    <h3>
      (a) Allume Energy may receive a commission from third parties in relation
      to provision of the Services to you;
    </h3>
    <h3>
      (b) Allume Energy does not, at any time, provide any guarantees
      whatsoever, whether express or implied, with respect to the success of the
      Service;
    </h3>
    <h3>
      (c) you shall remain solely responsible for assessing the implications and
      risks of using the Services;
    </h3>
    <h3>
      (d) these Terms do not create a relationship of employment, trust, joint
      venture, agency, partnership or other relationship of a fiduciary nature
      between the parties;
    </h3>
    <h3>
      (e) We may, upon reasonable notice to you, audit your use of the Portal
      for compliance with these Terms. In the event that such audit reveals any
      use of the Portal by you otherwise than in compliance with these Terms
      then, in addition to any other rights or remedies available to us under
      these Terms or at law, you shall indemnify us for:
    </h3>
    <h4>(i) all reasonable expenses related to such audit; and</h4>
    <h4>
      (ii) other liability or loss of revenue that we incur as a result of such
      non-compliance.
    </h4>
    <h2>
      3.8 You acknowledge that Allume Energy has entered into these Terms in
      reliance on the acknowledgements, representations and warranties given by
      you in these Terms.
    </h2>
    <p><strong>Intellectual Property</strong></p>
    <h2>
      3.9 The Website, the Services and all of the related products and services
      of Allume Energy (the
      <strong>Material</strong>) are solely owned by Allume Energy or its
      licensors. Unless otherwise indicated, all Intellectual Property Rights
      (including copyright) in the content and compilation of the Materials
      (including but not limited to text, graphics, logos, button icons, video
      images, audio clips, Website, code, scripts, lists, design elements and
      interactive features) (the <strong>Content</strong>) are owned or
      controlled for these purposes and are reserved by Allume Energy or its
      licensors.
    </h2>
    <h2>
      3.10 Allume Energy retains all Intellectual Property Rights, rights, title
      and interest (including copyrights, design, patents and trademarks) in the
      Portal, including the Material, the Content, and all related content
      therein. Nothing you do will transfer any interest in the Material to you,
      other than the grant of the licence to you below.
    </h2>
    <h2>
      3.11 Whilst using the Services, Allume Energy grants you a worldwide,
      non-exclusive, royalty-free, revocable licence during these Terms to:
    </h2>
    <h3>(a) use the Portal pursuant to these Terms;</h3>
    <h3>(b) download the Material and the Content;</h3>
    <h3>(c) your sole use of the Material and Content;</h3>
    <h3>(d) copy and store the Material and Content; and</h3>
    <h3>(e) print pages from the Website for your own personal use.</h3>
    <h2>
      3.12 Allume Energy does not grant you any other rights whatsoever in
      relation to the Material and/or the Content. All other rights are
      expressly reserved by Allume Energy.
    </h2>
    <h2>
      3.13 You may not, without the prior written consent of Allume Energy and
      the permission of any other relevant rights owner: broadcast, republish,
      upload to a third party, transmit, post, distribute, show or display or
      publish in public, adapt or change in any way the Material or Content for
      any purpose, other than for the purposes expressly allowed by these Terms.
      This prohibition does not extend to the Material and Content which are
      freely available for re-use or are in the public domain.
    </h2>
    <h1>4 Material and Content</h1>
    <h2>
      4.1 You may read and copy the Material and Content for your own needs, but
      you may not publish, resell or sub-licence it. Allume Energy makes no
      guarantees, representations or warranties about the accuracy or legal
      correctness of any of the Material or Content.
    </h2>
    <h2>4.2 Third Party Content</h2>
    <h3>
      (a) Allume Energy works with a number of partners and affiliates whose
      websites or mobile phone applications or databases may be linked with or
      provided by Allume Energy and are controlled by parties other than Allume
      Energy (each
      <strong>Third Party Content</strong>).
    </h3>
    <h3>
      (b) Allume Energy is not responsible and does not endorse or accept any
      responsibility for the availability, contents, products, services or use
      of any Third Party Content, any website accessed from any Third Party
      Content or any changes or updates to such sites. Allume Energy makes no
      guarantees about the content or quality of the products or services
      provided by such third parties.
    </h3>
    <h3>
      (c) By using any information, product, service, or functionality
      originating from the Services, you are allowing Allume Energy to share
      information with any third party with whom Allume Energy has a pertinent
      contractual relationship &ndash; any information necessary to facilitate
      its provisions of products, services, or functionality to you.
    </h3>
    <p><strong>User Data </strong></p>
    <h2>4.3 Ownership</h2>
    <h3>(a) Your User Data remains your property.</h3>
    <h3>
      (b) You grant us a non-exclusive, non-transferable, royalty-free,
      worldwide licence to access, copy, modify, use and store your User Data to
      the extent necessary for:
    </h3>
    <h4>
      (i) the purposes of accessing and using the Portal and performing our
      obligations under these Terms; and
    </h4>
    <h4>
      (ii) performing analytics, conducting research and developing user
      insights and products (<strong>Derivative Works</strong>).
    </h4>
    <h3>
      (c) Allume Energy will be the sole owner of the Derivative Works. You
      assign all Intellectual Property Rights, rights, title and interest
      (including copyrights, design, patents and trademarks) that may be created
      or existing in the Derivative Works to Allume Energy.
    </h3>
    <h2>4.4 Allume Energy</h2>
    <h3>
      You acknowledge and agree that Allume Energy may provide the User Data to
      any third party with a reasonable use of the User Data, including, but not
      limited to:
    </h3>
    <h4>(i) Any government agency or body;</h4>
    <h4>(ii) Your Premises Controller; and</h4>
    <h4>(iii) Law enforcement;</h4>
    <h2>4.5 Loss</h2>
    <h3>
      We and our Representatives will not be liable for any loss, corruption or
      destruction in the accessibility or useability of your User Data.
    </h3>
    <p><strong>Energy Data</strong></p>
    <h2>4.6 We own all Energy Data generated by the Portal.</h2>
    <h2>
      4.7 We grant you a non-exclusive, non-transferable, royalty-free and
      revocable, worldwide licence to access and view your Energy Data to the
      extent necessary for using the Portal in accordance with these Terms.
    </h2>
    <h2>
      4.8 We may, at our sole and absolute discretion, disclose, share or sell
      Energy Data to third parties provided that all such Energy Data is
      non-identifiable and anonymised.
    </h2>
    <p><strong>Allume Energy&rsquo;s use of your material</strong></p>
    <h2>
      4.9 By engaging Allume Energy to perform the Service, you acknowledge and
      agree that Allume Energy is granted an irrevocable, perpetual, worldwide,
      royalty free right to use your trade mark, logo, photographs,
      testimonials, feedback or name for the purpose of promoting Allume Energy
      or the Services, now and in the future. This includes any promotion,
      testimonials, marketing or advertising by way of online, in print, in
      social media, in competitions, advertisements, books and magazines, design
      publications and any other self-promotional or demonstrative purpose.
    </h2>
    <h1>5 Confidentiality</h1>
    <h2>
      5.1 If you receive or become aware of any Confidential Information that
      belongs to us, you must not use or disclose that Confidential Information,
      this includes that you will not divulge any Confidential Information to
      any third party or use the Confidential information for your own or
      another&rsquo;s benefit or for any personal use, except to the extent
      required to:
    </h2>
    <h3>
      (a) fulfil your obligations and exercise your rights under these Terms;
    </h3>
    <h3>(b) comply with the requirements of the law,</h3>
    <h3>(c) but before making any disclosure you must:</h3>
    <h4>(i) notify us in writing as soon as reasonably practicable; and</h4>
    <h4>
      (ii) only disclose that part of the Confidential Information as is
      necessary to comply with relevant requirements.
    </h4>
    <h1>6 General Disclaimer</h1>
    <h2>
      6.1 Nothing in the Terms limits or excludes any guarantees, warranties,
      representations or conditions implied or imposed by law, including the
      Australian Consumer Law (or any liability under them) which by law may not
      be limited or excluded.
    </h2>
    <h3>
      (a) If you are not a Consumer (under the Australian Consumer Law), you
      agree that Allume Energy has no direct or indirect liability (including in
      negligence) to you in any way related to your use of the Website or the
      Services.
    </h3>
    <h3>
      (b) If you are a Consumer (under the Australian Consumer Law), Allume
      Energy limits all its direct and indirect liability (including in
      negligence) to you to the Consumer Guarantees under the Australian
      Consumer Law.
    </h3>
    <h3>
      (c) Where there is a breach of the Consumer Guarantees, then to the
      maximum extent permitted by law, Allume Energy&rsquo;s liability to you is
      limited to resupply of the Services or payment of the cost of re-supplying
      the Services.
    </h3>
    <h2>6.2 Subject to this clause and to the extent permitted by law:</h2>
    <h3>
      (a) Allume Energy excludes all representations, warranties or guarantees,
      whether express or implied, by statute, trade or otherwise; and
    </h3>
    <h3>
      (b) Allume Energy will not be liable for any Claims, causes of action,
      damage or expenses arising out of or in connection with the Website or the
      Services (including as a result of not being able to use the Services or
      the late supply of the Services), whether at common law, in equity,
      pursuant to statute, or otherwise.
    </h3>
    <h1>7 Limitation of liability</h1>
    <h2>
      7.1 Allume Energy&rsquo;s total liability arising out of or in connection
      with the Services or the Terms, however arising, including at law, in
      equity, under statute, or otherwise, will not exceed the resupply of the
      Service to you.
    </h2>
    <h2>
      7.2 You expressly understand and agree that Allume Energy will not be
      liable to you for any direct, indirect, incidental, special consequential
      or exemplary damages which may be incurred by you or your Representative,
      however caused and under any theory of liability. This includes, but is
      not limited to, any Consequential Loss.
    </h2>
    <h2>
      7.3 You expressly understand and agree that Allume Energy will not be
      liable to you for your use of the information in the Services.
    </h2>
    <h1>8 Indemnity</h1>
    <h2>
      8.1 You agree to indemnify Allume Energy and its Representatives from and
      against all Claims, liabilities, costs, expenses, loss and damage
      (including legal fees on a full indemnity basis) incurred, suffered or
      arising out of or in connection with:
    </h2>
    <h3>
      (a) inaccurate or misleading information provided by you or your
      Representative;
    </h3>
    <h3>
      (b) you or your Representatives&rsquo; use, access, or control of User
      Data;
    </h3>
    <h3>(c) any breach of the Terms by you or your Representative; or</h3>
    <h3>
      (d) any direct or indirect consequences of you or your Representative
      accessing, using or transacting on the Website or the Services or attempts
      to do so.
    </h3>
    <h2>8.2 This indemnity will survive termination of the Terms.</h2>
    <h1>9 Termination of Services</h1>
    <h2>
      9.1 The Terms will continue to apply until the deletion of your account,
      or until terminated by Allume Energy as set out below.
    </h2>
    <h2>
      9.2 You may cancel your account at any time through the functionality
      provided on the Portal or with written notice to Allume Energy.
    </h2>
    <h2>
      9.3 Allume Energy may at any time without notice, terminate the Terms with
      you if:
    </h2>
    <ul>
      <li>
        you have breached any provision of the Terms or intend to breach any
        provision;
      </li>
      <li>Allume Energy is required to do so by law;</li>
      <li>
        the partner, if any, with whom Allume Energy offered the Services to you
        has terminated its relationship with Allume Energy or ceased to offer
        the Services to you;
      </li>
      <li>
        Allume Energy is transitioning to no longer providing the Services to
        Users in the country in which you are resident or from which you use the
        service;
      </li>
      <li>
        the provision of the Services to you by Allume Energy is, in the opinion
        of Allume Energy, no longer commercially viable;
      </li>
      <li>if you have used the Services:</li>
    </ul>
    <h4>(i) in breach of any law;</h4>
    <h4>(ii) in a way that is misleading or deceptive;</h4>
    <h4>
      (iii) in a way which is unreasonable as determined by Allume Energy at its
      absolute discretion; or
    </h4>
    <h4>
      (iv) in a manner which can or does bring Allume Energy into disrepute or
      could damage Allume Energy&rsquo;s reputation as determined by Allume
      Energy at its absolute discretion.
    </h4>
    <h2>
      9.4 Subject to local applicable laws, Allume Energy reserves the right to
      discontinue or cancel your access to the Services at any time and may
      suspend or deny, in its sole discretion, your access to all or any portion
      of the Website or the Services without notice if you breach any provision
      of the Terms or any applicable law or if your conduct damages Allume
      Energy&rsquo;s name or reputation or violates the rights of those of
      another party.
    </h2>
    <h2>
      9.5 Upon the termination of your account, all of the legal rights,
      obligations and liabilities that you and Allume Energy have benefited
      from, been subject to (or which have accrued over time whilst the Terms
      have been in force) or which are expressed to continue indefinitely, shall
      be unaffected by this cessation, and the provisions of this clause shall
      continue to apply to such rights, obligations and liabilities
      indefinitely.
    </h2>
    <h1>10 Force Majeure Event</h1>
    <h2>
      10.1 If a Force Majeure Event prevents us from performing, in whole or in
      part, our obligations under these Terms, we will give you written notice
      of the Force Majeure Event and the anticipated impact on our performance
      of our obligations.
    </h2>
    <h2>
      10.2 If a Force Majeure Event continues for more than 30 days, either
      party may terminate these Terms by written notice to the other without
      liability for damages of any kind, including for Consequential Loss.
    </h2>
    <h1>11 Dispute Resolution</h1>
    <h1>
      If a dispute arises out of or relates to the Terms, either party may not
      commence any Tribunal or Court proceedings in relation to the dispute,
      unless the following clauses have been complied with (except where urgent
      interlocutory relief is sought):
    </h1>
    <h3>
      (a) A party to the Terms claiming a dispute (Dispute) has arisen under the
      Terms, must give written notice to the other party detailing the nature of
      the Dispute, the desired outcome and the action required to settle the
      Dispute.
    </h3>
    <h3>
      (b) On receipt of that notice (Notice) by that other party, the parties to
      the Terms (the Dispute Parties) must within seven (7) days of the Notice
      endeavour in good faith to resolve the Dispute expeditiously by
      negotiation or such other means upon which they may mutually agree.
    </h3>
    <h3>
      (c) If for any reason whatsoever, twenty-one (21) days after the date of
      the Notice, the Dispute has not been resolved, the Dispute Parties must
      either agree upon selection of a mediator or request that an appropriate
      mediator be appointed by the President of the Australian Mediation
      Association or his or her nominee.
    </h3>
    <h3>
      (d) The Dispute Parties are equally liable for the fees and reasonable
      expenses of a mediator and the cost of the venue of the mediation and
      without limiting the foregoing undertake to pay any amounts requested by
      the mediator as a pre-condition to the mediation commencing.
    </h3>
    <h3>
      (e) It is agreed that the mediation will be held in Melbourne, Victoria,
      Australia.
    </h3>
    <h3>
      (f) For the avoidance of doubt, the Dispute Parties need not attend the
      mediation or other means of resolution in person. The Dispute Parties may
      attempt to resolve the matter via any means of electronic communications.
    </h3>
    <h3>
      (g) All communications concerning negotiations made by the Dispute Parties
      arising out of and in connection with this dispute resolution clause are
      confidential and to the extent possible, must be treated as "without
      prejudice" negotiations for the purpose of applicable laws of evidence.
    </h3>
    <h3>
      (h) If thirty (30) days have elapsed after the start of a mediation of the
      Dispute and the Dispute has not been resolved, either Dispute Party may
      ask the mediator to terminate the mediation and the mediator must do so.
    </h3>
    <h3>
      (i) In the event that the Dispute is not resolved at the conclusion of the
      mediation, either Dispute Party may institute legal proceedings concerning
      the subject matter of the Dispute thereafter.
    </h3>
    <h1>12 Governing Law and Jurisdiction</h1>
    <h2>
      The Services offered by Allume Energy are intended for residents of
      Australia. These Terms are governed by and are to be constructed in
      accordance with the laws of the State of Victoria, Australia. You submit
      to the exclusive jurisdiction of the courts situated in Melbourne,
      Victoria, Australia in respect of all matters arising out of or relating
      to these Terms, the Website, the Services and their performance.
    </h2>
    <h1>13 Notice</h1>
    <h2>
      13.1 Allume Energy may provide any notice to you under the Terms by
      sending a message to your email address. The notice provided by Allume
      Energy to you by email shall be deemed to have been properly given on the
      date Allume Energy sends the email, regardless of whether you have
      received the email.
    </h2>
    <h2>
      13.2 Unless specified otherwise, any notices provided by you to Allume
      Energy must be in writing and be delivered either in person, or via
      registered post to 1/1 Bromham Place, Richmond, Victoria 3121 to the
      attention of the Chief Operations Officer. Notices provided by registered
      post shall be deemed to have properly given three days after they are
      posted (if posted).
    </h2>
    <h1>14 Severance</h1>
    <h2>
      If any part of the Terms is found to be void or unenforceable, that part
      shall be severed and the rest of the Terms shall remain in force.
    </h2>
    <h1>15 Waiver</h1>
    <h2>
      15.1 A waiver of any right, power or remedy under this Agreement must be
      in writing signed by the party granting it.
    </h2>
    <h2>
      15.2 A waiver is only effective in relation to the particular obligation
      or breach in respect of which it is given. It is not to be taken as an
      implied waiver of any other obligation or breach or as an implied waiver
      of that obligation or breach in relation to any other occasion.
    </h2>
    <h2>
      15.3 The fact that a party fails to do, or delays in doing, something the
      party is entitled to do under this Agreement does not amount to a waiver.
    </h2>
    <p><strong>Assignment</strong></p>
    <h2>
      15.4 Allume Energy may assign or transfer its rights or obligations under
      the Terms without your consent.
    </h2>
    <h2>
      15.5 You may not assign or transfer your rights or obligations under the
      Terms without prior written consent of Allume Energy. A purported
      assignment without written consent will be deemed to be void and convey no
      rights.
    </h2>
    <p><strong>Definitions and Interpretation</strong></p>
    <h2>15.6 <strong>Definitions</strong></h2>
    <p>In these Terms, the following definitions apply:</p>
    <p>
      <strong>Allume Energy, </strong>"<strong>us</strong>"<strong>, </strong
      >"<strong>we</strong>"<strong> and </strong>"<strong>our</strong>" means
      Allume Energy Pty Ltd ACN 605 671 494, and includes its Representatives
      and related bodies corporate;
    </p>
    <p>
      <strong>Australian Consumer Law</strong> means the Competition and
      Consumer Act (2010) Cth Schedule 2;
    </p>
    <p>
      <strong>Claim</strong> means any claim, demand, remedy, suit, action,
      proceeding, right of action, claim for compensation or claim for abatement
      of any monetary obligation, whether arising under contract (including
      these Terms), in tort (including negligence), at common law, in equity,
      under statute, under an indemnity or otherwise;
    </p>
    <p>
      <strong>Confidential Information</strong> means all confidential,
      non-public or proprietary information exchanged between the parties
      relating to the affairs of each party or which comes into a party&rsquo;s
      possession pursuant to, or as a result of, any negotiations or discussions
      in connection with these Terms including the existence, nature and terms
      of these Terms, whether disclosed verbally, in writing, in electronic form
      or by any other means;
    </p>
    <p>
      <strong>Consequential Loss</strong> means the following, however arising
      and even if it is reasonably contemplated by the parties as a likely
      result of breach of these Terms:
    </p>
    <h3>(a) incidental, special, remote or unforeseeable loss or damage;</h3>
    <h3>(b) loss of, damage to, breach of, or corruption of data;</h3>
    <h3>(c) breach of security;</h3>
    <h3>
      (d) loss of revenue, profit, income, bargain, opportunity, use,
      production, business, contract, goodwill, reputation, anticipated savings,
      loss caused by business interruption, or the cost of obtaining new
      financing or maintaining existing financing, but excluding loss of any
      amounts that would, but for the act or omission of a party, have otherwise
      been payable under these Terms;
    </h3>
    <h3>
      (e) costs or expenses incurred to prevent or reduce loss or damage which
      otherwise may be incurred or suffered by a third party; or
    </h3>
    <h3>
      (f) loss or damage of the nature set out above in clauses (a) to (e)
      (inclusive) that is incurred or suffered by or to a third party;
    </h3>
    <p>
      "<strong>you or </strong>"<strong>your</strong>" means the user of the
      Portal;
    </p>
    <p><strong>User Data</strong> means:</p>
    <h3>
      any information, text, drawings or other materials which are embodied in
      any electronic or tangible medium and which are supplied by you to use;
    </h3>
    <h3>your Personal Information; and</h3>
    <h3>Confidential Information belonging to you,</h3>
    <p>
      but excludes (to the extent that it doesn't fall within the above
      categories in (a) - (c) inclusive):
    </p>
    <h3>Energy Data;</h3>
    <h3>the Portal; and</h3>
    <h3>Confidential Information belonging to us;</h3>
    <p>
      <strong>Energy Data</strong> means data generated through the use of the
      Portal and the SolShare Hardware and includes, but is not limited to,
      information regarding:
    </p>
    <h3>the consumption of solar electricity by each Occupant;</h3>
    <h3>
      (g) the consumption of electricity from the grid by each Occupant; and
    </h3>
    <h3>(h) exports of solar electricity to the grid by each Occupant.</h3>
    <p>
      <strong>Force Majeure Event</strong> means any event or occurrence outside
      the reasonable control of a party regardless of whether it is reasonably
      contemplated by the parties as a likely result of breach of the Agreement;
    </p>
    <p>
      <strong>Intellectual Property (IP) Rights</strong> includes all industrial
      and intellectual property rights, and includes any patents, registered
      designs, copyright (including future copyright), trade or service marks
      (whether registered or unregistered), trade secrets, know-how, rights in
      relation to circuit layouts, or other proprietary right, and applications
      for, and rights to apply for, registration of any of the foregoing;
    </p>
    <p>
      <strong>Major Update</strong> means an Update that will or is likely to
      materially affect your ability to access and use the Portal as
      contemplated by this Agreement;
    </p>
    <p>
      <strong>Material </strong>means material in whatever form, including
      without limitation documents, specifications, reports, products,
      equipment, information, data, graphic layouts, images and software;
    </p>
    <p>
      <strong>Occupant </strong>means any person or business that is occupying
      part of the Premises;
    </p>
    <p>
      <strong>Personal Information</strong> means data by which a person may be
      personally identified, including a person's name, postal address, email
      address, telephone number and any other information a party collects,
      including that which is defined as personal or personally identifiable
      information under applicable Privacy Laws;
    </p>
    <p>
      <strong>Portal</strong> means Allume Energy&rsquo;s software that allows a
      Premises Controller or Occupant to monitor an Occupant&rsquo;s electricity
      usage at the Premises;
    </p>
    <p>
      <strong>Premises</strong> means the building and the land that the
      Premises Controller manages or controls or in which the Occupant is a
      resident or tenant;
    </p>
    <p>
      <strong>Premises Controller</strong> means the housing provider, body
      corporate, or government body that manages or controls the Premises;
    </p>
    <p>
      <strong>Privacy Laws</strong> means the Privacy Act 1988 (Cth) and any
      applicable State or Territory law in relation to privacy;
    </p>
    <p>
      <strong>Representative</strong> means, in respect of a party, any person
      acting for or on behalf of the party and includes any director, officer,
      employee, agent, contractor or sub-contractor of the party;
    </p>
    <p>
      <strong>SolShare Hardware</strong> means Allume Energy&rsquo;s
      behind-the-meter solar sharing system installed in the Premises; and
    </p>
    <p>
      <strong>Update</strong> means, in relation to the Technology, software
      which has been provided or produced to alter, improve or add to the
      functionality of the Technology or to overcome defects in the Technology.
    </p>
    <h2>15.7 <strong>Interpretation </strong></h2>
    <h2>In the interpretation of this Agreement:</h2>
    <h3>
      (a) references to legislation or provisions of legislation include changes
      or re-enactments of the legislation and statutory instruments and
      regulations issued under the legislation;
    </h3>
    <h3>
      (b) words denoting the singular include the plural and vice versa, words
      denoting individuals or persons include bodies corporate and vice versa,
      references to documents or agreements also mean those documents or
      agreements as changed, novated or replaced, and words denoting one gender
      include all genders;
    </h3>
    <h3>
      (c) grammatical forms of defined words or phrases have corresponding
      meanings;
    </h3>
    <h3>
      (d) parties must perform their obligations on the dates and times fixed by
      reference to the capital city of Victoria;
    </h3>
    <h3>
      (e) if the day on or by which anything is to be done under this Agreement
      falls on a day which is not a Business Day, then it must be done on the
      next Business Day;
    </h3>
    <h3>
      (f) references to a party are intended to bind their executors,
      administrators and permitted transferees;
    </h3>
    <h3>
      (g) the word "including" (and related forms including "includes") will be
      understood as meaning "including without limitation";
    </h3>
    <h3>
      (h) the terms and provisions of this Agreement will not be construed
      against the drafter, and the parties agree that the language of this
      Agreement shall be construed as a whole according to its fair meaning and
      not strictly for or against any of the parties;
    </h3>
    <h3>
      (i) references to numbered clauses, paragraphs and schedule are references
      to the relevant clause or paragraph in or schedule to this Agreement; and
    </h3>
    <h3>
      (j) obligations under this Agreement affecting more than one party bind
      them jointly and each of them severally.
    </h3>
  </div>
</template>

<script>
export default {
  name: 'TC',
  computed: {
    scroll() {
      return 0; //document.getElementById("tcbox").scrollHeight;
    },
  },
};
</script>

<style lang="less" scoped>
.tcbox {
  height: 400px;
  overflow: scroll;
  padding: 10px 20px;

  * {
    font-size: 17px;
  }
}
</style>
