<template>
  <Card :title="data.name" class="battery-card" no-footer>
    <div class="battery-card__progress-container">
      <Battery :percent="data.percent" />
    </div>
    <a-divider />
    <a-row :gutter="[24, 0]">
      <a-col :span="10">
        <p class="battery-card__info-title">Status</p>
      </a-col>
      <a-col :span="14">
        <a-badge
          :status="data.chargeInfo.status"
          :text="data.chargeInfo.message"
          :color="data.chargeInfo.color"
        />
      </a-col>
    </a-row>
    <a-row :gutter="[24, 0]">
      <a-col :span="10">
        <p class="battery-card__info-title">SolShare No.</p>
      </a-col>
      <a-col :span="14">
        <a-tag color="cyan">{{ data.solshareId }}</a-tag>
      </a-col>
    </a-row>
    <a-row :gutter="[24, 0]">
      <a-col :span="10">
        <p class="battery-card__info-title">Charging Sched.</p>
      </a-col>
      <a-col :span="14">{{ data.chargeScheduledInfo }}</a-col>
    </a-row>
    <a-row :gutter="[24, 0]">
      <a-col :span="10">
        <p class="battery-card__info-title">Discharging Sched.</p>
      </a-col>
      <a-col :span="14">{{ data.dischargeScheduledInfo }}</a-col>
    </a-row>
    <a-button
      type="primary"
      @click="$emit('scheClick', data)"
      class="battery-card__btn"
    >
      Schedule
    </a-button>
    <a-tooltip placement="topLeft" title="Time to refresh data">
      <div class="battery-card__countdown-bar">
        <div
          class="battery-card__countdown-bar-inner"
          :style="{ width: `${100 - (100 / 3600) * currTick}%` }"
        />
      </div>
    </a-tooltip>
  </Card>
</template>

<script>
import Card from './Card.vue';
import Battery from './Battery.vue';
export default {
  name: 'BatteryInfoCard',
  props: { data: Object, currTick: Number },
  components: { Card, Battery },
};
</script>

<style lang="less" scoped>
.battery-card {
  position: relative;
  text-align: left;

  &__progress-container {
    display: flex;
    justify-content: center;
  }

  &__info-title {
    text-align: right;
    color: #333;
  }

  &__btn {
    display: block;
    margin: 10px auto;
    width: 150px;
  }

  &__countdown-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
  }

  &__countdown-bar-inner {
    transition: width 200ms;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 50%;
    background-color: #1890ff;
    height: 100%;
    border-radius: 50px;
  }
}
</style>
