/**
 * @author Wentong Liu
 */
'use strict';

/**
 * Trim the number with fixed length of decimal place
 * @param num
 * @param length: Decimal length
 */
export const trimDecimal = (num, length = 4) => {
  const extent = Math.pow(10, length);
  return Math.round(num * extent) / extent;
};

/**
 * Get the sum of a number array
 * @param array
 */
export const sumOfArray = function (array) {
  return array.reduce((total, num) => total + num, 0);
};
