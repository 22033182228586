<template>
  <div>
    <Card title="Branding">
      <p>
        Customize how your brand appears in the resident portal, emails and
        invoices. (Recommended width is 300px)
      </p>
      <a-upload
        class="provider-setting__upload"
        list-type="picture-card"
        :show-upload-list="false"
        accept="image/x-png,image/jpeg"
        name="branding"
        :action="`/v2/files/upload-branding`"
        @change="onUploadChange"
      >
        <img
          v-if="$store.state.user.logo"
          :src="$store.state.user.logo"
          alt="avatar"
          :style="{ width: '100%' }"
        />
        <div v-else>
          <LoadingOutlined v-if="loading" />
          <PlusOutlined v-else />
          <div>Upload</div>
        </div>
      </a-upload>
      <template #footer>
        <div>
          <a-button
            :disabled="!isDirtyLogo"
            :loading="isSubmitting"
            type="primary"
            @click="updateProviderLogo"
          >
            Save
          </a-button>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '../components/Card.vue';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons-vue';
export default {
  name: 'Branding',
  components: { Card, PlusOutlined, LoadingOutlined },
  data() {
    return {
      loading: false,
      isSubmitting: false,
      isDirtyLogo: false,
    };
  },
  methods: {
    /**
     * Handle upload onChange event
     * Get the image URL and then dispatch the action to notify Redux to update the state
     * @param info
     */
    onUploadChange(info) {
      this.loading = true;
      this.isDirtyLogo = true;
      if (info.file.status === 'done') {
        const { response } = info.file;
        if (response.Location) {
          this.$store.commit('setLogo', response.Location);
          this.$message.success(`${info.file.name} file uploaded successfully`);
        }
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
      this.loading = false;
    },
    /**
     * Update logo image
     */
    async updateProviderLogo() {
      this.isSubmitting = true;
      try {
        const { id, logo } = this.$store.state.user;
        await this.$http.put(this.$api.updateProvider(id), { logo });
        this.$message.success('Update successfully');
      } catch (e) {
        this.$message.error('Update failed');
      }
      this.isSubmitting = false;
    },
  },
};
</script>

<style lang="less" scoped>
.provider-setting {
  &__upload {
    :deep(.ant-upload.ant-upload-select-picture-card) {
      width: 300px;
      height: 180px;
    }
  }
}
</style>
