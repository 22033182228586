export const AlertType = Object.freeze({
  BatteryTrip: 'batteryTrip',
  InverterTrip: 'inverterTrip',
  SolshareOffline: 'solshareOffline',
  EnergyLimit: 'energyLimit',
});

export const SEARCH_METER_CODE = {
  NOT_FOUND: 404,
  OFFLINE: 406,
  ALREADY_COMMISSIONED: 409,
  READY_TO_GO: 200,
  FORBIDDEN: 403,
  NOT_COMMISSIONED: 423,
};

export const SOLAR_DELIVERY_METHOD = {
  FULLY_OPTIMISED: 'optimise',
  OPTIMISED_AND_EVEN: 'optimise_even',
  UNEQUAL_ALLOCATION: 'optimise_weight',
  NET_METERING: 'net_metering',
};

export const ALGORITHM_TYPE = {
  DEMAND_BASED_DELIVERY: 'DEMAND_BASED_DELIVERY',
  CUSTOM_ALLOCATION: 'CUSTOM_ALLOCATION',
};

export const COUNTRY_CODE = {
  Australia: 'AU',
  'United Kingdom': 'GB',
  'United States': 'US',
  'New Zealand': 'NZ',
  Germany: 'DE',
};

export function getCurrencyForCountry(country) {
  switch (country) {
    case COUNTRY_CODE['United Kingdom']:
      return '£';
    case COUNTRY_CODE.Germany:
      return '€';
    default:
      return '$';
  }
}

export const DEFAULT_ALGO_WEIGHT = Object.freeze([
  [
    [0, 0, null],
    [0, 1, null],
    [0, 2, null],
    [0, 3, null],
    [0, 4, null],
  ],
  [
    [1, 0, null],
    [1, 1, null],
    [1, 2, null],
    [1, 3, null],
    [1, 4, null],
  ],
  [
    [2, 0, null],
    [2, 1, null],
    [2, 2, null],
    [2, 3, null],
    [2, 4, null],
  ],
]);

export const UNEQUAL_ALLOCATION_TYPE = {
  PERCENT: '%',
  KW: 'kW',
  FT: 'ft',
};

export function getEmissionFactorSource(country) {
  switch (country) {
    case COUNTRY_CODE['United Kingdom']:
      return 'UK Government GHG Reporting Conversion Factors';
    case COUNTRY_CODE['United States']:
      return 'US Environment Protection Agency (EPA)';
    case COUNTRY_CODE.Australia:
      return 'AU Government Clean Energy Regulator';
    case COUNTRY_CODE.Germany:
    case COUNTRY_CODE['New Zealand']:
      return 'Statista';
    default:
      return 'International Energy Agency (IEA)';
  }
}
