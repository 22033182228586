/**
 * @author Wentong Liu
 */
'use strict';

export default {
  /* Auth */
  login: '/auth/customer-login',
  register: '/auth/register',
  registerResend: '/auth/register-resend',
  getLogo: (email) => `/providers/email/${email}`,

  /* projects */
  addProject: `/projects/link-project`,
  addProjectAdmin: (serial) => `/admin-projects/link-project/${serial}`,
  inviteProvider: (projectId) => `/projects/invite/${projectId}`,

  /* Performance */
  getMyProjectsProvider: `/providers/me/projects`,
  getMyProjectsConsumer: `/consumers/me/properties`,
  getProjectDetails: (projectId) => `/projects/${projectId}`,
  getProjectHourlySnapshots: (projectId, from, to) =>
    `/projects/${projectId}/snapshots?type=hourly&from=${from}&to=${to}`,
  getMeterHourlySnapshots: (meterId, from, to) =>
    `/meters/${meterId}/snapshots?type=hourly&from=${from}&to=${to}`,
  getMeterWithProperties: (meterId) => `/meters/${meterId}/properties`,
  getPropertyHourlySnapshots: (propertyId, from, to) =>
    `/properties/${propertyId}/snapshots?type=hourly&from=${from}&to=${to}`,
  downloadCSV: (projectId) => `/snapshot/${projectId}/csv`,

  /* Alert */
  getAlertConfiguration: (propertyId) => `/projects/${propertyId}/alerts`,
  createBatteryTripAlert: `/alert-setting/alert-battery-trips`,
  createInverterTripAlert: `/alert-setting/alert-inverter-trips`,
  createSolshareOfflineAlert: `/alert-setting/alert-solshare-offlines`,
  createEnergyLimitAlert: `/alert-setting/alert-energy-limits`,
  createAlertEmail: `/alert-setting/alert-emails`,
  updateBatteryTripAlert: (alertId) =>
    `/alert-setting/alert-battery-trips/${alertId}`,
  updateInverterTripAlert: (alertId) =>
    `/alert-setting/alert-inverter-trips/${alertId}`,
  updateSolshareOfflineAlert: (alertId) =>
    `/alert-setting/alert-solshare-offlines/${alertId}`,
  updateEnergyLimitAlert: (alertId) =>
    `/alert-setting/alert-energy-limits/${alertId}`,
  updateAlertEmail: (alertId) => `/alert-setting/alert-emails/${alertId}`,

  /* Battery */
  getBatteryInfo: (projectId) => `/projects/${projectId}/batteries`,
  chargingSchedule: (deviceId) => `/charging-schedules/${deviceId}`,

  /* Setting */
  updateProvider: `/providers/profile`,
  updateEmail: `/auth/change-email`,
  updatePassword: `/auth/change-pwd`,
  deleteAccount: (token) => `/auth/delete-account/${token}`,
  confirmDeleteAccount: `/auth/delete-account`,
  forgetPassword: `/auth/forget-password`,
  resetPassword: `/auth/reset-password`,
  tcRead: `/auth/tc-read`,

  /* Site Configuration */
  getSolshareInfo: (serial) => `/service-interface/system-info/${serial}`,
  uploadConfig: (serial) =>
    `/service-interface/solar-delivery-method/${serial}`,

  /* Billing */
  getPaymentPortalSession: `/payment/portal`,
  getSubscription: `/payment/subscription`,
  postSubscriptionUsage: (subscriptionId) => `/payment/usage/${subscriptionId}`,

  /* Financial Tab */
  uploadSolarAccessFee: (meterId) => `/meters/${meterId}/solar-access-fee`,
  togglePropertySolarAccessFeeEnabled: (meterId) =>
    `/meters/${meterId}/toggle-solar-access-fee`,
  getMeterPropertySavings: (meterId) => `/meters/${meterId}/property-savings`,
  getMeterRevenue: (meterId) => `/meters/${meterId}/revenue`,
};
