<template>
  <Card title="Financials per unit" no-footer>
    <a-table :data-source="data" :pagination="false" :loading="loading">
      <a-table-column key="unit" title="Unit" data-index="unit" />
      <a-table-column
        key="monthly-fee"
        title="Solar Connection Fee (monthly)"
        data-index="monthlyFee"
        align="center"
      />
      <a-table-column
        key="monthly-savings"
        title="Estimated Solar Savings (so far this month)"
        data-index="monthlySavings"
        align="center"
      />
      <a-table-column
        key="net-savings"
        title="Net Tenant Savings (all time)"
        data-index="netSavings"
        align="center"
      />
      <a-table-column key="toggle" title="Part of Access Fee">
        <template #default="{ record }">
          <a-tooltip
            :title="disabled ? 'Please set solar connection fee first' : ''"
          >
            <a-switch
              :loading="loading"
              :disabled="loading || disabled"
              v-model:checked="record.enabled"
              @change="(enabled) => onPropertyEnabledChange(record.id, enabled)"
            />
          </a-tooltip>
        </template>
      </a-table-column>
    </a-table>
    <a-popconfirm
      title="Changing this will re-calculate all other tenants' solar access fee, confirm?"
      ok-text="Yes"
      cancel-text="No"
      :disabled="loading || disabled"
      @confirm="onPropertyEnabledSaved"
    >
      <a-button
        type="primary"
        :loading="loading"
        :disabled="disabled"
        class="save-button"
      >
        Save
      </a-button>
    </a-popconfirm>
  </Card>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

defineProps({
  data: Array,
  loading: Boolean,
  disabled: Boolean,
  onPropertyEnabledChange: Function,
  onPropertyEnabledSaved: Function,
});

const store = useStore();
const disabled = computed(
  () => store.state.financial.solarAccessFee.solarConnectionFee === null,
);
</script>

<style scoped>
.save-button {
  margin-top: 20px;
}
</style>
