<template>
  <ProjectStatisticCard :loading="loading" :tip="tip" :icon="TreeIcon">
    <template #title>
      <span>
        You've reduced
        <span>
          {{ trimDecimal(value, 2) }}
          {{ unit }}
        </span>
        of CO<sub>2</sub>
      </span>
    </template>
  </ProjectStatisticCard>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { trimDecimal } from '../utils/energy-util';
import ProjectStatisticCard from './ProjectStatisticCard';
import TreeIcon from '../assets/icon/tree.svg?url';
import { getEmissionFactorSource } from '../utils/constants';

defineProps({
  value: Number,
  unit: String,
  loading: Boolean,
});

const store = useStore();

const tip = computed(
  () =>
    `The value is calculated based on metrics from ${getEmissionFactorSource(
      store.state.country,
    )}.`,
);
</script>
