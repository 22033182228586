<template>
  <div class="background" />
</template>

<script>
export default {
  name: 'Background',
};
</script>

<style scoped>
.background {
  position: absolute;
  height: 872px;
  transform: skewY(-12deg);
  top: -300px;
  left: 0;
  right: 0;
  z-index: -9999;
  background-color: #f7fafc;
  overflow: hidden;
}
</style>
