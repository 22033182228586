<template>
  <a-modal
    destroyOnClose
    title="Schedule the next event"
    :maskClosable="false"
    :footer="false"
    v-model:visible="modalVisible"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      @finish="onFinish"
      :labelCol="{ span: 10 }"
      :wrapperCol="{ span: 12 }"
    >
      <a-form-item label="Charging start time" name="chargingStart">
        <a-date-picker
          v-model:value="form.chargingStart"
          placeholder="Select time"
          :showToday="false"
          :allowClear="false"
          :style="{ width: 195 }"
          :format="datePickerFormat"
          :showTime="{ minuteStep: 10 }"
          :disabledDate="
            (current) => current && current < this.$moment().startOf('day')
          "
          :disabledTime="(current) => disabledChangingStartTime(current)"
        />
      </a-form-item>
      <a-form-item label="Discharging start time" name="dischargingStart">
        <a-date-picker
          v-model:value="form.dischargingStart"
          placeholder="Select time"
          :showToday="false"
          :allowClear="false"
          :style="{ width: 195 }"
          :format="datePickerFormat"
          :showTime="{ minuteStep: 10 }"
          :disabledDate="
            (current) => current && current < this.$moment().startOf('day')
          "
          :disabledTime="(current) => disabledChangingStartTime(current)"
        />
      </a-form-item>
      <a-form-item label="Discharging end time" name="dischargingEnd">
        <a-date-picker
          v-model:value="form.dischargingEnd"
          placeholder="Select time"
          :showToday="false"
          :allowClear="false"
          :style="{ width: 195 }"
          :format="datePickerFormat"
          :showTime="{ minuteStep: 10 }"
          :disabledDate="
            (current) => current && current < this.$moment().startOf('day')
          "
          :disabledTime="(current) => disabledChangingStartTime(current)"
        />
      </a-form-item>
      <a-form-item :wrapperCol="{ span: 12, offset: 10 }">
        <a-button
          block
          :loading="isSubmitting"
          type="primary"
          htmlType="submit"
          :style="{ width: 195 }"
        >
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'BatterySchedulerModal',
  props: {
    deviceId: String,
    visible: Boolean,
  },
  data() {
    return {
      datePickerFormat: 'DD/MM/YYYY HH:mm',
      isSubmitting: false,
      form: {
        chargingStart: undefined,
        dischargingStart: undefined,
        dischargingEnd: undefined,
      },
      rules: {
        chargingStart: [
          { required: true, message: 'The time of charging start is required' },
        ],
        dischargingStart: [
          {
            required: true,
            message: 'The time of discharging start is required',
          },
          {
            validator: this.validator1,
          },
        ],
        dischargingEnd: [
          {
            required: true,
            message: 'The time of discharging end is required',
          },
          {
            validator: this.validator2,
          },
        ],
      },
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
  },
  methods: {
    validator1(rule, value) {
      if (!value || this.form.chargingStart < value) {
        return Promise.resolve();
      }
      return Promise.reject(
        'The discharging start time cannot be before charging start time.',
      );
    },
    validator2(rule, value) {
      if (!value || this.form.dischargingStart < value) {
        return Promise.resolve();
      }
      return Promise.reject(
        'The discharging end time cannot be before discharging start time.',
      );
    },
    disabledChangingStartTime(current, compared = this.$moment()) {
      if (current) {
        return {
          disabledHours: () =>
            compared.date() === current.date()
              ? Array.from(Array(compared.hour()).keys())
              : [],
          disabledMinutes: (hour) =>
            compared.date() === current.date() && compared.hour() === hour
              ? Array.from(Array(compared.minute()).keys())
              : [],
        };
      }
      return {};
    },
    /**
     * Update schedule settings to the server
     */
    async submitScheduleSetting(
      chargeStartedAt,
      chargeEndedAt,
      disChargeStartedAt,
      disChargeEndedAt,
    ) {
      this.isSubmitting = true;
      try {
        await this.$http.post(this.$api.chargingSchedule(this.deviceId), {
          chargeStartedAt,
          chargeEndedAt,
          disChargeStartedAt,
          disChargeEndedAt,
          recurring: false,
        });
        this.$message.success('Schedule successfully');
        this.modalVisible = false;
      } catch (e) {
        this.$message.error('Schedule unsuccessfully');
      }
      this.isSubmitting = false;
    },
    onFinish(values) {
      const { chargingStart, dischargingStart, dischargingEnd } = values;
      this.submitScheduleSetting(
        chargingStart.unix(),
        dischargingStart.unix(),
        dischargingStart.unix(),
        dischargingEnd.unix(),
      );
    },
  },
};
</script>

<style scoped></style>
