<template>
  <div class="connection-group" :key="phaseIndex">
    <a-table
      :data-source="connectedUnits"
      :pagination="false"
      size="small"
      :loading="loading"
    >
      <a-table-column
        key="unit"
        :title="`Unit (Phase L${phaseIndex + 1})`"
        data-index="unit"
      />
      <a-table-column
        v-if="shouldDisplayWeightInput"
        key="weight"
        :title="`${roundToDown(available, 2)} kW available`"
        width="135px"
        align="right"
        :data-tn="`available-${phaseIndex}`"
      >
        <template #default="{ record }">
          <span v-if="!record.enabled">0{{ suffix }}</span>

          <span
            v-else-if="isCalculatedConnection(record.conn)"
            :data-tn="`calculated-${record.phase}-${record.conn}`"
          >
            {{ calculatedText }}
          </span>
          <div class="input-wrapper" v-else>
            <a-input-number
              class="input"
              v-model:value="form.weights[record.conn]"
              :disabled="readonly"
              :data-tn="`connection-weight-${record.phase}-${record.conn}`"
            />
            <span class="suffix">{{ suffix }}</span>
          </div>
        </template>
      </a-table-column>
      <a-table-column
        key="enabled"
        title="Enabled"
        data-index="enabled"
        width="100px"
        align="center"
      >
        <template #default="{ record }">
          <span>
            <a-switch
              :disabled="readonly"
              v-model:checked="record.enabled"
              @change="() => onConnectedChange(record.phase, record.conn)"
              :data-tn="`connection-enable-${record.phase}-${record.conn}`"
            />
          </span>
        </template>
      </a-table-column>
    </a-table>

    <!--   this form is only for validation purpose   -->
    <a-form ref="form" :model="form" :rules="rules">
      <a-form-item name="connections">
        <a-input v-if="false" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { UNEQUAL_ALLOCATION_TYPE } from '@/utils/constants';
import { roundTo, roundToDown } from '@/utils/round';
export default {
  name: 'ConnectionGroup',
  props: {
    phaseIndex: Number,
    connectedUnits: Array,
    enabledUnits: Array,
    algorithmWeight: Array,
    loading: Boolean,
    available: Number,
    suffix: String,
    readonly: Boolean,
    defaultEven: Boolean,
    shouldDisplayWeightInput: Boolean,
  },
  emits: ['update:enabledUnits', 'update:algorithmWeight'],
  watch: {
    form: {
      handler() {
        this.algorithmWeightValue = this.getAlgoWeight();
      },
      deep: true,
    },
    suffix(newVal, oldVal) {
      if (
        oldVal === UNEQUAL_ALLOCATION_TYPE.PERCENT &&
        newVal === UNEQUAL_ALLOCATION_TYPE.KW
      ) {
        this.inputConnections
          .filter((conn) => this.form.weights[conn] !== null)
          .forEach((conn) => {
            this.form.weights[conn] = roundTo(
              (this.form.weights[conn] * this.available) / 100,
              2,
            );
          });
      }

      if (
        oldVal === UNEQUAL_ALLOCATION_TYPE.KW &&
        newVal === UNEQUAL_ALLOCATION_TYPE.PERCENT
      ) {
        this.inputConnections
          .filter((conn) => this.form.weights[conn] !== null)
          .forEach((conn) => {
            this.form.weights[conn] = roundTo(
              (this.form.weights[conn] / this.available) * 100,
              2,
            );
          });
      }
    },
  },
  mounted() {
    this.getAlgoWeight();
  },
  data() {
    return {
      roundToDown,
      form: {
        weights: [
          this.algorithmWeight?.[0]?.[2] ?? 0,
          this.algorithmWeight?.[1]?.[2] ?? 0,
          this.algorithmWeight?.[2]?.[2] ?? 0,
          this.algorithmWeight?.[3]?.[2] ?? 0,
          this.algorithmWeight?.[4]?.[2] ?? 0,
        ],
      },
      rules: {
        connections: [{ validator: this.validateConnection }],
      },
    };
  },
  computed: {
    enabledUnitsValue: {
      get() {
        return this.enabledUnits;
      },
      set(v) {
        // set disabled units
        for (let i = 0; i < 5; i++) {
          if (!v.includes(i) && this.algorithmWeightValue?.[i]?.[2]) {
            this.algorithmWeightValue[i][2] = 0;
          }
        }
        this.$emit('update:enabledUnits', v);
      },
    },
    algorithmWeightValue: {
      get() {
        return this.algorithmWeight;
      },
      set(v) {
        this.$emit('update:algorithmWeight', v);
      },
    },
    inputConnections() {
      return this.enabledUnitsValue.slice(0, -1);
    },
    calculatedConnection() {
      if (this.enabledUnitsValue.length === 0) {
        return -1;
      }
      return this.enabledUnitsValue[this.enabledUnitsValue.length - 1];
    },
    calculatedValue() {
      switch (this.suffix) {
        case UNEQUAL_ALLOCATION_TYPE.PERCENT:
          return roundTo(
            this.inputConnections.reduce(
              (acc, cur) => acc - Number(this.form.weights[cur]),
              100,
            ),
            2,
          );

        case UNEQUAL_ALLOCATION_TYPE.KW:
          return roundTo(
            this.inputConnections.reduce(
              (acc, cur) => acc - Number(this.form.weights[cur]),
              this.available,
            ),
            2,
          );

        default:
          return 0;
      }
    },
    calculatedText() {
      if (this.calculatedValue < 0) {
        return 'calculated N/A';
      } else {
        return `calculated ${this.calculatedValue}${this.suffix}`;
      }
    },
  },
  methods: {
    validateConnection() {
      if (!this.shouldDisplayWeightInput) {
        return Promise.resolve();
      }

      const errored = this.inputConnections
        .filter((conn) => this.form.weights[conn] === null)
        .map((conn) => conn + 1);

      if (errored.length > 0) {
        return Promise.reject(
          `Please input number to line ${errored.join(', ')}.`,
        );
      }

      if (this.calculatedValue < 0) {
        if (this.suffix === UNEQUAL_ALLOCATION_TYPE.PERCENT) {
          return Promise.reject(`Total input percent is greater than 100%.`);
        } else {
          return Promise.reject(
            `Total input allocation for all connections must be smaller than the available kW.`,
          );
        }
      }

      return Promise.resolve();
    },

    validate() {
      this.algorithmWeightValue = this.getAlgoWeight();
      return this.$refs.form.validate();
    },
    getAlgoWeight() {
      if (this.calculatedValue === null) {
        return null;
      }

      // prefill even value to input connections
      if (
        this.defaultEven &&
        this.inputConnections.length > 0 &&
        Number(this.form.weights[this.inputConnections[0]]) === 0
      ) {
        const evenValue = Math.floor(10000 / this.enabledUnits.length) / 100;
        this.inputConnections.forEach(
          (conn) => (this.form.weights[conn] = evenValue),
        );
      }

      // set calculated value
      this.form.weights[this.calculatedConnection] = this.calculatedValue;

      switch (this.suffix) {
        case UNEQUAL_ALLOCATION_TYPE.PERCENT:
          return [0, 1, 2, 3, 4].map((conn) => [
            this.phaseIndex,
            conn,
            this.enabledUnitsValue.includes(conn)
              ? Number(this.form.weights[conn])
              : 0,
          ]);

        case UNEQUAL_ALLOCATION_TYPE.KW:
          return [0, 1, 2, 3, 4].map((conn) => [
            this.phaseIndex,
            conn,
            this.enabledUnitsValue.includes(conn)
              ? (100 * Number(this.form.weights[conn])) / this.available
              : 0,
          ]);
      }
    },
    isCalculatedConnection(connIndex) {
      return (
        connIndex === this.enabledUnitsValue[this.enabledUnitsValue.length - 1]
      );
    },
    onConnectedChange(phase, conn) {
      // disable a conn
      if (this.enabledUnitsValue.includes(conn)) {
        this.enabledUnitsValue = this.enabledUnitsValue.filter(
          (x) => x !== conn,
        );
      } else {
        // enable a conn
        this.enabledUnitsValue.push(conn);
        this.enabledUnitsValue.sort();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.connection-group {
  margin-bottom: 20px;

  .input-wrapper {
    position: relative;

    .input {
      width: 100%;

      :deep(.ant-input-number-input-wrap) {
        padding-right: 20px;
      }

      :deep(.ant-input-number-handler-wrap) {
        display: none;
      }
    }

    .suffix {
      position: absolute;
      right: 10px;
      line-height: 30px;
      top: 1px;
    }

    .input,
    :deep(.input input) {
      text-align: center;

      &:last-child {
        grid-column-start: 1;
        grid-column-end: 3;

        .calculated {
          color: #aaa;
        }
      }
    }
  }

  :deep(tr) {
    height: 49px;
  }
}
</style>
