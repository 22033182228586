<template>
  <div class="setting">
    <a-menu
      v-model:selectedKeys="selectedKeys"
      mode="inline"
      class="setting__menu"
    >
      <a-menu-item v-if="$store.getters.getRole === 'provider'" key="branding">
        <router-link to="/setting/branding">
          <TrademarkOutlined /> Branding
        </router-link>
      </a-menu-item>
      <a-menu-item key="profile">
        <router-link to="/setting/profile">
          <UserOutlined /> Profile
        </router-link>
      </a-menu-item>
      <a-menu-item
        v-if="showBillingTab"
        key="billing"
        data-tn="billing-menu-item"
      >
        <router-link to="/setting/billing">
          <DollarCircleOutlined /> Billing
        </router-link>
      </a-menu-item>
    </a-menu>
    <div class="setting__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import {
  TrademarkOutlined,
  UserOutlined,
  DollarCircleOutlined,
} from '@ant-design/icons-vue';
export default {
  name: 'Settings',
  components: { TrademarkOutlined, UserOutlined, DollarCircleOutlined },
  data() {
    return {
      selectedKeys: [
        this.$store.getters.getRole === 'provider' ? 'branding' : 'profile',
      ],
    };
  },
  computed: {
    showBillingTab() {
      return (
        this.$store.getters.getRole === 'provider' &&
        this.$store.state.pageSetting.showBilling === true
      );
    },
  },
  mounted() {
    if (this.$route.matched.length === 2) {
      this.$router.replace(`/setting/${this.selectedKeys[0]}`);
    } else {
      const slash = this.$route.path.lastIndexOf('/') + 1;
      const selected = this.$route.path.substring(slash);
      this.selectedKeys = [selected];
    }
  },
  watch: {
    $route(to) {
      if (to.fullPath === '/setting') {
        this.$router.replace(`/setting/${this.selectedKeys[0]}`);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.setting {
  display: flex;

  &__content {
    flex: 1;
    margin-left: 20px;
  }

  &__menu {
    width: 240px;
    border-right: 0;
    background: transparent;
  }
}
</style>
