import moment from 'dayjs';

export function normalizedDateRange(rangeDates) {
  const from = rangeDates[0].startOf('day');
  const to =
    rangeDates[1].startOf('day').clone().add(1, 'day') > moment()
      ? moment()
      : rangeDates[1].startOf('day').clone().add(1, 'day');

  return [from.unix(), to.unix(), moment().utcOffset()];
}
