<template>
  <v-chart
    class="chart"
    :option="option"
    :init-options="defaultOption"
    :loading="loading"
    :loading-options="loadingOption"
    autoresize
  />
</template>

<script setup>
import { provide } from 'vue';
import { use, registerTheme } from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
} from 'echarts/components';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import VChart, { THEME_KEY } from 'vue-echarts';
import theme from '../assets/style/macarons.json';

defineProps({
  loading: Boolean,
  option: Object,
  width: String,
  height: String,
});

use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  CanvasRenderer,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
]);

registerTheme('macarons', theme);
provide(THEME_KEY, 'macarons');

const defaultOption = {
  grid: {
    left: '20px',
    right: '20px',
    bottom: '20px',
    top: '30px',
    containLabel: true,
  },
  xAxis: {
    name: 'time',
    type: 'category',
    data: [],
  },
  yAxis: {
    name: 'kWh',
    type: 'value',
  },
  tooltip: {
    trigger: 'axis',
  },
  legend: {
    data: ['Loading...'],
  },
};
const loadingOption = {
  color: '#048041',
  text: 'Loading...',
  textColor: '#000',
};
</script>

<style scoped>
.chart {
  width: 100%;
  height: 400px;
  padding-bottom: 10px;
}
</style>
