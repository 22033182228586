<template>
  <a-modal
    v-model:visible="modalVisible"
    :footer="null"
    destroyOnClose
    title="Forgot Password"
    :maskClosable="false"
  >
    <a-form
      @finish="handleForgetPassword"
      layout="vertical"
      :model="forgetForm"
      :rules="rules"
    >
      <a-form-item name="email" label="Please input your Email" validateFirst>
        <a-input
          v-model:value="forgetForm.email"
          size="large"
          placeholder="Email"
          autocomplete="username"
        />
      </a-form-item>

      <a-form-item>
        <a-button type="primary" html-type="submit" :loading="isLoading">
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { validateEmailAddress } from '@/utils/validators';

export default {
  name: 'ForgetPasswordModal',
  props: {
    visible: Boolean,
  },
  data() {
    return {
      isLoading: false,
      forgetForm: {
        email: '',
      },
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          {
            validator: validateEmailAddress,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    async handleForgetPassword() {
      this.isLoading = true;
      try {
        await this.$http.post(this.$api.forgetPassword, {
          email: this.forgetForm.email,
        });
        this.$message.success(
          'We have sent an Email to you. Please check your email inbox for further instructions',
        );

        this.modalVisible = false;
        this.forgetForm.email = '';
      } catch (e) {
        this.$message.error(e);
      }
      this.isLoading = false;
    },
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
  },
};
</script>

<style scoped></style>
