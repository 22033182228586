<template>
  <a-form
    :model="form"
    :rules="rules"
    :wrapperCol="{ span: 24 }"
    @finish="updatePassword"
    :style="{ width: '400px' }"
  >
    <a-form-item name="oldPwd">
      <a-input-password
        autocomplete="current-password"
        placeholder="Old password"
        v-model:value="form.oldPwd"
      />
    </a-form-item>

    <a-form-item
      name="newPwd"
      extra="Must contain numeric and character, length from 6 to 20."
    >
      <a-input-password
        autocomplete="new-password"
        placeholder="New password"
        v-model:value="form.newPwd"
      />
    </a-form-item>

    <a-form-item name="confirmPwd">
      <a-input-password
        autocomplete="new-password"
        placeholder="Confirm password"
        v-model:value="form.confirmPwd"
      />
    </a-form-item>

    <a-button :loading="isSubmitting" type="primary" htmlType="submit">
      Update
    </a-button>
  </a-form>
</template>

<script>
import { validateStrongPassword } from '@/utils/validators';

export default {
  name: 'PasswordForm',
  data() {
    return {
      isSubmitting: false,
      form: {
        oldPwd: '',
        newPwd: '',
        confirmPwd: '',
      },
      rules: {
        oldPwd: [{ required: true, message: 'Old password is required.' }],
        newPwd: [
          { required: true, message: 'New password is required.' },
          {
            validator: validateStrongPassword,
          },
        ],
        confirmPwd: [
          {
            required: true,
            message: 'Please confirm your password',
          },
          {
            validator: this.validateConfirmPassword,
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Update user password
     * After updated, navigate to login page to ask the user to login again
     * @param values
     */
    async updatePassword(values) {
      this.isSubmitting = true;
      try {
        const { oldPwd, newPwd } = values;
        await this.$http.put(this.$api.updatePassword, { oldPwd, newPwd });
        this.$message.success(
          'Update password successfully, please login again.',
          5,
        );
        this.$store.dispatch('logout');
      } catch (e) {
        this.$message.error('The old password is incorrect.');
      }
      this.isSubmitting = false;
    },
    validateConfirmPassword(rule, value) {
      if (!value || this.form.newPwd === value) {
        return Promise.resolve();
      }
      return Promise.reject("Two passwords you enter doesn't match");
    },
  },
};
</script>

<style scoped></style>
