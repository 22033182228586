<template>
  <div class="battery">
    <div class="battery__label">
      {{ isNaN(percentage) ? 'Unknown' : `${percentage}%` }}
    </div>
    <span class="battery__entity">
      <span
        class="battery__entity-inner"
        :style="{
          width: `${percentage}%`,
          backgroundColor: getColor(percentage),
        }"
      />
    </span>
  </div>
</template>

<script>
export default {
  name: 'Battery',
  props: {
    percent: Number,
  },
  computed: {
    percentage() {
      let percentage = Math.round(Number(this.percent) * 100) / 100;
      if (percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    },
  },
  methods: {
    getColor(percent) {
      if (percent >= 95) {
        return '#32cd32';
      } else if (percent >= 80) {
        return '#73d13d';
      } else if (percent >= 65) {
        return '#ffc53d';
      } else if (percent >= 50) {
        return '#fadb14';
      } else if (percent >= 30) {
        return '#ff7a45';
      } else if (percent >= 15) {
        return '#ff4d4f';
      } else {
        return '#cf1322';
      }
    },
  },
};
</script>

<style lang="less" scoped>
.battery {
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    margin-right: 10px;
    font-size: 35px;
    color: #333;
  }

  &__entity {
    box-sizing: border-box;
    display: inline-block;
    position: relative;
    border: 4px solid #000;
    width: 85px;
    height: 40px;
    border-radius: 4px;
    vertical-align: middle;

    &-inner {
      display: block;
      box-sizing: border-box;
      height: 32px;
      position: absolute;
      border: 1px solid #fff;
    }

    &:after {
      box-sizing: border-box;
      content: '';
      display: block;
      background: #000;
      width: 6px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: -11px;
      margin-top: -8px;
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
