<template>
  <p>You can delete your Allume account if you wish.</p>
  <a-button type="danger" @click="isModalVisible = true">
    Delete Account
  </a-button>
  <a-modal
    title="Delete Account"
    :visible="isModalVisible"
    @ok="handleOk"
    @cancel="isModalVisible = false"
    okText="Confirm"
  >
    <p>
      You will receive a confirmation Email sent to
      <b>{{ $store.state.user.email }}</b> by clicking confirm.
    </p>
  </a-modal>
</template>

<script>
export default {
  name: 'DeleteAccount',
  data() {
    return {
      isModalVisible: false,
    };
  },
  methods: {
    handleOk() {
      this.$http.post(this.$api.confirmDeleteAccount);
      this.isModalVisible = false;
    },
  },
};
</script>

<style scoped></style>
