<template>
  <div class="provider-performance">
    <PerformanceControlPanel
      :launchDate="project?.launchAt || null"
      :rangeDates="rangeDates"
      :isFetching="isFetching"
      :properties="properties"
      :meters="meters"
      :meterValue="meterValue"
      :consumerValue="consumerValue"
      :propertyValue="propertyValue"
      :meterSelectOnChange="meterSelectOnChange"
      :propertySelectOnChange="propertySelectOnChange"
      :datePickerOnChange="datePickerOnChange"
      :apply="apply"
    />

    <div class="provider-performance__card-container">
      <a-row :gutter="[20, 20]">
        <a-col
          :span="24"
          :xs="{ span: 24 }"
          :sm="{ span: 24 }"
          :md="{ span: 24 }"
        >
          <CO2Card
            :value="sumOfArray(chartData.emissionReducedList)"
            :unit="chartData.emissionReducedUnit"
            :loading="isFetching"
          />
        </a-col>
        <a-col
          :span="6"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 6 }"
        >
          <ProjectStatisticCard
            :loading="isFetching"
            title="Solar Consumed"
            :amount="trimDecimal(sumOfArray(chartData.solarConsumedList), 2)"
            suffix="kWh"
            :icon="ConsumedIcon"
          />
        </a-col>
        <a-col
          :span="6"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 6 }"
        >
          <ProjectStatisticCard
            :loading="isFetching"
            title="Energy Demand"
            :amount="trimDecimal(sumOfArray(chartData.energyDemandList), 2)"
            suffix="kWh"
            :icon="DemandIcon"
          />
        </a-col>
        <a-col
          :span="6"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 6 }"
        >
          <ProjectStatisticCard
            :loading="isFetching"
            title="Solar Delivered"
            :amount="trimDecimal(sumOfArray(chartData.solarDeliveredList), 2)"
            suffix="kWh"
            :icon="DeliveredIcon"
          />
        </a-col>
        <a-col
          :span="6"
          :xs="{ span: 24 }"
          :sm="{ span: 12 }"
          :md="{ span: 6 }"
        >
          <ProjectStatisticCard
            :loading="isFetching"
            title="Solar Exported"
            :amount="trimDecimal(sumOfArray(chartData.solarExportedList), 2)"
            suffix="kWh"
            :icon="ExportedIcon"
          />
        </a-col>
      </a-row>
    </div>

    <div class="provider-performance__chart-container box-shadow">
      <div class="provider-performance__tips">
        Click
        <img :src="LegendIcon" alt="legend" />
        symbol to toggle graphs on and off
      </div>
      <ChartBase :loading="isFetching" :option="getChartData" />
    </div>
    <NEM12Toolkit
      v-if="$store.state.pageSetting?.nem12 ?? false"
      :from="rangeDates[0].format('YYYY-MM-DD')"
      :to="rangeDates[1].format('YYYY-MM-DD')"
      :project="project?.abbr"
    />
  </div>
</template>

<script setup>
import LegendIcon from '../../assets/icon/ic_legend.svg?url';
import ExportedIcon from '../../assets/icon/ic_exported.svg?url';
import DeliveredIcon from '../../assets/icon/ic_delivered.svg?url';
import DemandIcon from '../../assets/icon/ic_demand.svg?url';
import ConsumedIcon from '../../assets/icon/ic_consumed.svg?url';
import CO2Card from '../CO2Card.vue';
import ProjectStatisticCard from '../ProjectStatisticCard.vue';
import NEM12Toolkit from '../NEM12Toolkit.vue';
import ChartBase from '../ChartBase.vue';
import PerformanceControlPanel from '../PerformanceControlPanel.vue';
import { trimDecimal, sumOfArray } from '../../utils/energy-util';
import { normalizedDateRange } from '../../utils/date';
</script>

<script>
export default {
  name: 'PerformanceTabProvider',
  mounted() {
    this.getProjectSnapshots();
  },
  data() {
    return {
      isFetching: false, // Flag of network fetching
      properties: [], // Property dropdown list items
      meterValue: 'all', // Meter selection value
      propertyValue: 'all', // Property selection value
      consumerValue: 'all', // Consumer selection value
      rangeDates: [this.$moment().subtract(1, 'day'), this.$moment()], // time range picker values
      chartData: {
        xAxis: [],
        energyDemandList: [],
        solarConsumedList: [],
        solarDeliveredList: [],
        solarExportedList: [],
        emissionReducedList: [],
        emissionReducedUnit: 'kg',
      },
    };
  },
  computed: {
    project() {
      // Project info
      return this.$store.state.selectedProject;
    },
    meters() {
      // Meter dropdown list items
      return this.project?.meters ?? [];
    },
    /**
     * Mock up the EChart data source from the chartData state
     */
    getChartData() {
      const {
        xAxis,
        solarConsumedList,
        energyDemandList,
        solarExportedList,
        solarDeliveredList,
      } = this.chartData;
      const energyDemandLineName = 'Total Energy Demand';
      const solarConsumedLineName = 'Solar Consumed';
      const solarExportedLineName = 'Solar Exported';
      const solarDeliveredLineName = 'Solar Delivered';
      return {
        grid: {
          left: '20px',
          right: '20px',
          bottom: '20px',
          top: '30px',
          containLabel: true,
        },
        xAxis: [
          {
            data: xAxis,
            type: 'category',
            boundaryGap: false,
          },
        ],
        yAxis: [
          {
            name: 'kWh',
            type: 'value',
          },
        ],
        dataZoom: [
          {
            type: 'inside',
            start: 0,
            end: 100,
            minSpan: Math.min((24 / xAxis.length) * 100, 100),
            // zoomOnMouseWheel: false,
          },
          {
            start: 0,
            end: 100,
          },
        ],
        tooltip: {
          trigger: 'axis',
          backgroundColor: '#fff',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: [
            solarConsumedLineName,
            energyDemandLineName,
            solarDeliveredLineName,
            solarExportedLineName,
          ],
        },
        series: [
          {
            name: solarConsumedLineName,
            data: solarConsumedList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: energyDemandLineName,
            data: energyDemandList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: solarDeliveredLineName,
            data: solarDeliveredList,
            type: 'line',
            areaStyle: {},
          },
          {
            name: solarExportedLineName,
            data: solarExportedList,
            type: 'line',
            areaStyle: {},
          },
        ],
      };
    },
  },
  methods: {
    /**
     * Get property list by the meter id
     */
    async getPropertiesByMeterId(meterId) {
      try {
        const { data } = await this.$http.get(
          this.$api.getMeterWithProperties(meterId),
        );
        this.properties = data.properties;
      } catch (e) {
        this.$message.error('Get property list unsuccessfully');
      }
    },
    /**
     * Get project-level snapshots
     */
    async getProjectSnapshots() {
      this.isFetching = true;
      try {
        const [from, to] = normalizedDateRange(this.rangeDates);

        const { data } = await this.$http.get(
          this.$api.getProjectHourlySnapshots(
            this.$store.state.selectedProject.id,
            from,
            to,
          ),
        );
        this.handleHourlySnapshots(data);
      } catch (e) {
        this.$message.error('Get data unsuccessfully');
      }
      this.isFetching = false;
    },
    /**
     * Get meter-level snapshots by the meter id
     */
    async getMeterSnapshots(meterId) {
      this.isFetching = true;
      try {
        const [from, to] = normalizedDateRange(this.rangeDates);

        const { data } = await this.$http.get(
          this.$api.getMeterHourlySnapshots(meterId, from, to),
        );
        this.handleHourlySnapshots(data);
      } catch (e) {
        this.$message.error('Get data unsuccessfully');
      }
      this.isFetching = false;
    },
    /**
     * Covert snapshots to EChart data source
     */
    handleHourlySnapshots(snapshots) {
      const xAxis = [];
      const energyDemandList = [];
      const solarConsumedList = [];
      const solarDeliveredList = [];
      const solarExportedList = [];
      const emissionReducedList = [];
      snapshots.forEach((item) => {
        xAxis.push(this.$moment(item.startAt).format('DD/MM, HH:mm'));
        energyDemandList.push(trimDecimal(item.energyDemand));
        solarConsumedList.push(trimDecimal(item.solarConsumed));
        solarDeliveredList.push(trimDecimal(item.solarDelivered));
        solarExportedList.push(trimDecimal(item.solarExported));
        emissionReducedList.push(trimDecimal(item.emissionReduced));
      });
      this.chartData = {
        xAxis,
        energyDemandList,
        solarConsumedList,
        solarDeliveredList,
        solarExportedList,
        emissionReducedList,
        emissionReducedUnit: snapshots?.[0]?.emissionReducedUnit ?? 'kg',
      };
    },
    /**
     * Get property snapshots by the property id
     */
    async getPropertySnapshots(propertyId) {
      this.isFetching = true;
      try {
        const [from, to] = normalizedDateRange(this.rangeDates);

        const { data } = await this.$http.get(
          this.$api.getPropertyHourlySnapshots(propertyId, from, to),
        );

        this.handleHourlySnapshots(data);
      } catch (e) {
        this.$message.error('Get data unsuccessfully');
      }
      this.isFetching = false;
    },
    /**
     * First dropdown - meter selection
     */
    meterSelectOnChange(meterId) {
      if (meterId === 'all') {
        this.meterValue = meterId;
        this.propertyValue = 'all';
        this.consumerValue = 'all';
        this.properties = [];
      } else {
        this.meterValue = meterId;
        this.propertyValue = 'all';
        this.consumerValue = 'all';
        this.getPropertiesByMeterId(meterId);
      }
    },
    /**
     * The second dropdown - property selection
     */
    propertySelectOnChange(propertyId) {
      if (propertyId === 'all') {
        this.getPropertiesByMeterId(this.meterValue);
        this.consumerValue = propertyId;
        this.propertyValue = propertyId;
      } else {
        this.consumerValue = propertyId;
        this.propertyValue = propertyId;
      }
    },
    datePickerOnChange(dates) {
      if (dates) {
        this.rangeDates = dates;
      }
    },
    apply() {
      if (this.meterValue === 'all') {
        this.getProjectSnapshots();
      } else if (this.propertyValue === 'all') {
        this.getPropertiesByMeterId(this.meterValue);
        this.getMeterSnapshots(this.meterValue);
      } else {
        this.getPropertySnapshots(this.propertyValue);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../assets/style/variables';

.provider-performance {
  &__card-container {
    margin-bottom: 20px;
  }

  &__chart-container {
    background-color: #fff;
    margin-bottom: 20px;
    position: relative;
    padding: 25px 30px 20px;
  }

  &__radio-button {
    position: absolute;
    z-index: 1;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__radio-button-label {
    font-size: 12px;
  }

  &__tips {
    text-align: center;
    margin-bottom: 10px;
    font-size: 12px;
    color: #888;
  }
}

@media screen and (max-width: @mobile-width) {
  .provider-performance {
    &__chart-container {
      padding: 20px 0 0;
    }
  }
}
</style>
