<template>
  <a-modal
    v-model:visible="modalVisible"
    :footer="null"
    destroyOnClose
    title="Create Account"
    :mask-closable="false"
  >
    <a-form
      v-if="!emailSent"
      layout="vertical"
      :model="registerForm"
      :rules="rules"
      @finish="handleRegister"
    >
      <a-form-item name="email" label="Email" validateFirst>
        <a-input
          v-model:value="registerForm.email"
          size="large"
          placeholder="Email"
        />
      </a-form-item>
      <a-form-item name="password" label="Password" validateFirst>
        <a-input-password
          v-model:value="registerForm.password"
          size="large"
          placeholder="Password"
          autocomplete="off"
        />
      </a-form-item>
      <a-form-item name="firstName" label="First Name">
        <a-input
          v-model:value="registerForm.firstName"
          size="large"
          placeholder="First Name"
        />
      </a-form-item>
      <a-form-item name="lastName" label="Last Name">
        <a-input
          v-model:value="registerForm.lastName"
          size="large"
          placeholder="Last Name"
        />
      </a-form-item>

      <a-form-item>
        <vue-recaptcha
          sitekey="6LfQ5kgjAAAAAOswLUMLdhmd_X--iXFZUhIumlvt"
          @verify="recaptcha"
        />
      </a-form-item>

      <a-form-item>
        <a-button
          type="primary"
          html-type="submit"
          :loading="isLoading"
          :disabled="registerForm.recaptcha === ''"
        >
          Register
        </a-button>
      </a-form-item>
    </a-form>
    <div v-else>
      <div class="check-email">
        We’ve sent an email to your inbox. Please check and follow the
        instructions in it.
      </div>
      <div>
        Not receiving the email?
        <span v-if="countdown === 0">
          Click <a class="resend" @click="resend">here</a> to resend
        </span>
        <span v-else>Resend after {{ countdown }} seconds</span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {
  validateEmailAddress,
  validateStrongPassword,
} from '@/utils/validators';
import { VueRecaptcha } from 'vue-recaptcha';

export default {
  name: 'RegisterModal',
  props: {
    visible: Boolean,
  },
  components: { VueRecaptcha },
  data() {
    return {
      isLoading: false,
      emailSent: false,
      countdown: 0,
      resendAvailable: false,
      registerForm: {
        email: '',
        password: '',
        firstName: '',
        lastName: '',
        recaptcha: '',
      },
      rules: {
        email: [
          { required: true, message: 'Email is required', trigger: 'blur' },
          {
            validator: validateEmailAddress,
            trigger: 'blur',
          },
        ],
        password: [
          { required: true, message: 'Password is required', trigger: 'blur' },
          {
            validator: validateStrongPassword,
            trigger: 'blur',
          },
        ],
        firstName: [
          {
            required: true,
            message: 'First Name is required',
            trigger: 'blur',
          },
        ],
        lastName: [
          { required: true, message: 'Last Name is required', trigger: 'blur' },
        ],
      },
    };
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
  },
  methods: {
    async resend() {
      this.countDown();
      try {
        await this.$http.post(this.$api.registerResend, {
          email: this.registerForm.email,
        });
        this.$message.success(
          'We have sent an Email to you. Please check your email inbox for further instructions',
        );
      } catch (e) {
        this.$message.error(e);
      }
    },
    countDown() {
      this.countdown = 30;
      const intervalId = setInterval(() => {
        if (this.countdown > 0) {
          this.countdown--;
        }
        if (this.countdown === 0) {
          clearInterval(intervalId);
        }
      }, 1000);
    },
    async recaptcha(token) {
      this.registerForm.recaptcha = token;
    },
    async handleRegister() {
      this.isLoading = true;
      try {
        await this.$http.post(this.$api.register, this.registerForm);
        this.$message.success(
          'We have sent an Email to you. Please check your email inbox for further instructions',
        );
        this.emailSent = true;
      } catch (e) {
        this.$message.error(e);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.check-email {
  margin-bottom: 10px;
}
</style>
