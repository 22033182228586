import { validateIsEmail, validateIsStrongPassword } from '@/utils/auth';

export function validateEmailAddress(rule, value) {
  if (!validateIsEmail(value)) {
    return Promise.reject('Email is not valid.');
  }
  return Promise.resolve();
}

export function validateStrongPassword(rule, value) {
  if (!validateIsStrongPassword(value)) {
    return Promise.reject(
      'Password must contain number and alphabetical characters and the length is between 6 and 20.',
    );
  }
  return Promise.resolve();
}

export function validateSolshareSerialNumber(rule, value) {
  // see: https://regex101.com/r/N5Ft55/1
  if (/(\dP_)?\d{2,3}A?_\d{4}(_test)?/.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(`Invalid serial number, valid format:
              3P_35A_XXXX, 2P_100_XXXX`);
}
