<template>
  <a-modal
    destroyOnClose
    v-model:visible="modalVisible"
    title="Link a Solshare"
    :mask-closable="false"
    :footer="null"
  >
    <a-form
      layout="vertical"
      :model="form"
      :rules="rules"
      @finish="linkSolshare"
    >
      <a-form-item name="code" label="SerialNumber" validateFirst>
        <a-input v-model:value="form.code" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit" :loading="modal.isLoading">
          Link
        </a-button>
      </a-form-item>
    </a-form>
  </a-modal>
</template>
<script>
import { validateSolshareSerialNumber } from '@/utils/validators';
import { normalizeSerial } from '@/utils/string';

export default {
  name: 'AddProjectModal',
  props: { visible: Boolean, refresh: Function },
  data() {
    return {
      form: {
        code: '',
      },
      rules: {
        code: [
          {
            required: true,
            message: 'SerialNumber is required',
            trigger: 'blur',
          },
          {
            validator: validateSolshareSerialNumber,
            trigger: 'blur',
          },
        ],
      },
      modal: {
        visible: false,
        isLoading: false,
      },
    };
  },
  watch: {
    'form.code': {
      handler(newVal) {
        this.form.code = normalizeSerial(newVal);
      },
      deep: true,
    },
  },
  methods: {
    async linkSolshare() {
      try {
        this.modal.isLoading = true;
        await this.$http.post(this.$api.addProjectAdmin(this.form.code), {});
        this.$message.success('Project linked successfully');
        this.modalVisible = false;
        this.form.code = '';
        await this.refresh();
      } catch (e) {
        this.$message.error(e);
      }
      this.modal.isLoading = false;
    },
  },
  computed: {
    modalVisible: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit('update:visible', visible);
      },
    },
  },
};
</script>
<style scoped>
.info {
  margin-bottom: 20px;
}
</style>
