<template>
  <div class="container">
    <Background />
    <img class="logo" :src="logo" alt="logo" />
    <Card title="Reset Password" class="card">
      <p>Please enter your new password.</p>
      <a-form ref="form" :model="form" layout="vertical" :rules="rules">
        <a-form-item label="New password" prop="password">
          <a-input-password
            v-model:value="form.password"
            size="large"
            class="input-box"
          />
        </a-form-item>

        <a-form-item label="Re-enter password" prop="passwordConfirm">
          <a-input-password
            v-model:value="form.passwordConfirm"
            size="large"
            class="input-box"
          />
        </a-form-item>
      </a-form>

      <template #footer>
        <a-button type="primary" @click="resetClicked">Reset</a-button>
      </template>
    </Card>

    <Copyright />
  </div>
</template>

<script>
import Background from '../components/Background.vue';
import Copyright from '../components/Copyright.vue';
import Card from '../components/Card.vue';

export default {
  name: 'ResetPassword',
  components: { Card, Background, Copyright },
  data() {
    return {
      form: {
        password: '',
        passwordConfirm: '',
      },
      rules: {
        password: [
          { required: true, message: 'Please enter password', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!/^(?=.*[A-Za-z])(?=.*\d)\S{6,}$/.test(value)) {
                callback(
                  new Error(
                    `Password length must greater than 6 and contain at least one letter and one number`,
                  ),
                );
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
        passwordConfirm: [
          {
            required: true,
            message: 'Please input password again',
            trigger: 'blur',
          },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error("Two inputs don't match!"));
              } else {
                callback();
              }
            },
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    resetClicked() {
      try {
        this.$http.post(this.$api.resetPassword, {
          password: this.form.password,
          token: this.$route.params.token,
        });
        this.$store.dispatch('logout');
        this.$message.success('password reset successfully.');
      } catch (e) {
        this.$message.error(e);
      }
    },
  },
  computed: {
    logo() {
      return this.$store.getters.getLogo;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    height: 65px;
    display: block;
    margin: 100px auto 0;
  }

  .card {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
