<template>
  <div class="perf-control-panel box-shadow">
    <h2>{{ title }}</h2>
    <p>{{ description }}</p>
    <div class="perf-control-panel__control">
      <a-tooltip title="Solshare list" placement="topLeft">
        <a-select
          :value="meterValue"
          @change="meterSelectOnChange"
          placeholder="Select a SolShare"
          class="perf-control-panel__select perf-control-panel__meter"
          data-tn="site-configuration-select-solshare"
        >
          <a-select-option
            v-for="meter of meters"
            :key="meter.id"
            :value="meter[by]"
            :data-tn="`site-configuration-${meter.serialNum}`"
          >
            {{ getMeterDisplayName(meter) }}
          </a-select-option>
        </a-select>
      </a-tooltip>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

defineProps({
  title: String,
  description: String,
  meterValue: [String, Number],
  meterSelectOnChange: Function,
  by: {
    type: String, // can be either 'serialNum' or 'id'
    default: 'serialNum',
  },
});

const meters = computed(() => {
  return [...(store.state.selectedProject?.meters || [])].sort((a, b) => {
    if (a.alias && b.alias) {
      return a.alias.localeCompare(b.alias);
    }
    return a.serialNum.localeCompare(b.serialNum);
  });
});

function getMeterDisplayName(meter) {
  return meter.alias ? `${meter.serialNum} (${meter.alias})` : meter.serialNum;
}
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.perf-control-panel {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 20px;
  text-align: left;

  &__select {
    margin-right: 10px;
  }

  &__control {
    display: flex;
    align-items: center;
  }

  &__meter {
    width: 300px;
  }

  &__apply {
    margin-left: 11px;
  }
}

@media screen and (max-width: @mobile-width) {
  .perf-control-panel {
    &__control {
      flex-direction: column;
    }

    &__select {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__apply {
      margin-top: 10px;
      width: 100%;
      margin-left: 0;
    }

    &__meter {
      width: 100%;
    }
  }
}
</style>
