<template>
  <a-form
    :model="form"
    :rules="rules"
    @finish="updateEmail"
    :style="{ width: 400 }"
  >
    <p>Email: {{ $store.state.user.email }}</p>
    <a-form-Item
      name="newEmail"
      extra="This will also be your login email address."
      validateFirst
    >
      <a-input v-model:value="form.newEmail" placeholder="New email" />
    </a-form-Item>

    <a-button :loading="isSubmitting" type="primary" htmlType="submit">
      Update
    </a-button>
  </a-form>
</template>

<script>
import { validateEmailAddress } from '@/utils/validators';

export default {
  name: 'EmailForm',
  data() {
    return {
      isSubmitting: false,
      form: { newEmail: '' },
      rules: {
        newEmail: [
          { required: true, message: 'New email is required.' },
          {
            validator: validateEmailAddress,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    /**
     * Update user email
     * After updated, navigate to login page to ask the user to login again
     * @param values
     */
    async updateEmail(values) {
      this.isSubmitting = true;
      try {
        const { newEmail } = values;
        await this.$http.put(this.$api.updateEmail, { newEmail });
        this.$message.success(
          'Update email successfully, please login again.',
          5,
        );
        this.$store.dispatch('logout');
      } catch (e) {
        if ('response' in e) {
          this.$message.error(e.response.data.message);
        }
      }
    },
  },
};
</script>

<style scoped></style>
