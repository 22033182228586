<template>
  <div class="access-control">
    <h2>Invite other user to access the project</h2>
    <a-alert
      type="warning"
      show-icon
      class="alert"
      message="Are they an officially-appointed asset manager, building manager or
      solar partner providing ongoing maintenance support?"
    ></a-alert>

    <a-form
      ref="formRef"
      :model="formState"
      class="email-input-container"
      @submit="invite"
    >
      <a-form-item name="email" v-bind="validateInfos.email">
        <a-input
          v-model:value="formState.email"
          placeholder="Input email address"
        ></a-input>
      </a-form-item>

      <a-checkbox class="checkbox" v-model:checked="formState.checked">
        I confirm the owner of this email address will get admin access to the
        Solshare data for <u>{{ store.state.selectedProject.address }}</u
        >.
      </a-checkbox>

      <div>
        <a-button
          :loading="loading"
          :disabled="!formState.checked"
          type="primary"
          html-type="submit"
          class="invite-button"
        >
          Invite
        </a-button>
      </div>
    </a-form>
  </div>
  <div class="provider-list">
    <h2>Project Members</h2>
    <table>
      <tr
        :key="provider.email"
        v-for="provider of store.state.selectedProject.providers ?? []"
        class="provider-entry"
      >
        <td>
          <a-badge
            color="green"
            :text="`${provider.firstName} ${provider.lastName}`"
          />
        </td>
        <td>
          <span class="email">{{ provider.email }}</span>
        </td>
      </tr>
    </table>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { message, Form } from 'ant-design-vue';
import http from '../../http/http';
import api from '../../http/api';

const store = useStore();
const formRef = ref();

const formState = reactive({
  email: '',
  checked: false,
});

const rulesRef = reactive({
  email: [
    {
      type: 'email',
      required: true,
      message: 'please input a valid email address',
      trigger: 'blur',
    },
  ],
});

const { validate, validateInfos } = Form.useForm(formState, rulesRef);

const loading = ref(false);

async function invite() {
  await validate();

  loading.value = true;
  try {
    await http.post(api.inviteProvider(store.state.selectedProject.id), {
      email: formState.email,
      projectId: store.state.selectedProject.id,
      projectAddress: store.state.selectedProject.address,
    });
    formState.email = '';
    message.success('Invitation sent successfully.');
  } catch (e) {
    message.error(e);
  }
  loading.value = false;
}
</script>

<style lang="less" scoped>
.access-control {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 20px;

  .checkbox {
    margin-top: 10px;
  }

  .alert {
    margin-bottom: 20px;
  }

  .invite-button {
    margin-top: 20px;
  }
}

.provider-list {
  background: #fff;
  padding: 25px 30px;

  .provider-entry {
    margin-top: 8px;

    .email {
      margin-left: 10px;
      font-size: 12px;
    }
  }
}
</style>
