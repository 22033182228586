<template>
  <div class="container">
    <Background />
    <img class="logo" :src="logo" alt="logo" />
    <Card title="Delete Account" class="card">
      <p>We are sorry to see you go.</p>
      <p>Click the button below to delete the account.</p>

      <template #footer>
        <a-button type="danger" @click="deleteClicked">Confirm</a-button>
      </template>
    </Card>

    <Copyright />
  </div>
</template>

<script>
import Background from '../components/Background.vue';
import Copyright from '../components/Copyright.vue';
import Card from '../components/Card.vue';
export default {
  name: 'DeleteAccount',
  components: { Card, Background, Copyright },
  methods: {
    deleteClicked() {
      this.$http.del(this.$api.deleteAccount(this.$route.params.token));
      this.$store.dispatch('logout');
    },
  },
  computed: {
    logo() {
      return this.$store.getters.getLogo;
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .logo {
    height: 65px;
    display: block;
    margin: 100px auto 0;
  }

  .card {
    width: 600px;
    margin: 0 auto;
  }
}
</style>
