<template>
  <div class="alert">
    <a-alert
      v-if="alertEmails.length <= 0"
      showIcon
      type="warning"
      message="Your alert setting doesn't work right now since you haven't set any email recipient yet."
      class="alert__alert-banner"
    />
    <a-row :gutter="[24, 24]">
      <a-col
        :span="13"
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 13 }"
      >
        <Card no-padding no-footer>
          <template #title>
            <span> <BellOutlined /> Alert Criteria </span>
          </template>

          <template #extra>
            <a-button type="primary" @click="alertCreationOnClick">
              <PlusOutlined /> Add
            </a-button>
          </template>

          <a-spin :spinning="isSubmitting || isFetching">
            <AlertListItem
              v-if="alerts.batteryTrip"
              title="Battery Tripped"
              :isSubmitting="isSubmitting"
              :isSwitchChecked="alerts.batteryTrip.isActivated"
              :onSwitchChange="
                (checked, item) =>
                  updateAlertActivated(checked, item, AlertType.BatteryTrip)
              "
              :item="alerts.batteryTrip"
              :onDelete="(item) => deleteAlert(AlertType.BatteryTrip, item)"
            />
            <AlertListItem
              v-if="alerts.inverterTrip"
              title="Inverter Tripped"
              :isSubmitting="isSubmitting"
              :isSwitchChecked="alerts.inverterTrip.isActivated"
              :onSwitchChange="
                (checked, item) =>
                  updateAlertActivated(checked, item, AlertType.InverterTrip)
              "
              :item="alerts.inverterTrip"
              :onDelete="(item) => deleteAlert(AlertType.InverterTrip, item)"
            />
            <AlertListItem
              v-if="alerts.solshareOffline"
              title="Solshare Offline"
              :isSubmitting="isSubmitting"
              :isSwitchChecked="alerts.solshareOffline.isActivated"
              :onSwitchChange="
                (checked, item) =>
                  updateAlertActivated(checked, item, AlertType.SolshareOffline)
              "
              :item="alerts.solshareOffline"
              :desc="`>= ${alerts.solshareOffline.timeLength} h`"
              :onEdit="(item) => editAlert(AlertType.SolshareOffline, item)"
              :onDelete="(item) => deleteAlert(AlertType.SolshareOffline, item)"
            />
            <AlertListItem
              v-if="alerts.energyLimit"
              title="Energy Below Limit"
              :isSubmitting="isSubmitting"
              :isSwitchChecked="alerts.energyLimit.isActivated"
              :onSwitchChange="
                (checked, item) =>
                  updateAlertActivated(checked, item, AlertType.EnergyLimit)
              "
              :item="alerts.energyLimit"
              :desc="`<= ${alerts.energyLimit.threshold} kWh`"
              :onEdit="(item) => editAlert(AlertType.EnergyLimit, item)"
              :onDelete="(item) => deleteAlert(AlertType.EnergyLimit, item)"
            />
            <a-empty
              v-if="
                !alerts.batteryTrip &&
                !alerts.inverterTrip &&
                !alerts.energyLimit &&
                !alerts.solshareOffline
              "
              :image="emptyImage"
              description="You haven't set any alert yet."
              :style="{ paddingBottom: 35 }"
            />
          </a-spin>
        </Card>
      </a-col>
      <a-col
        :span="11"
        :xs="{ span: 24 }"
        :sm="{ span: 24 }"
        :md="{ span: 11 }"
      >
        <Card no-padding no-footer>
          <template #title>
            <span> <MailOutlined /> Email Recipients </span>
          </template>

          <template #extra>
            <a-button type="primary" @click="emailRecipientCreateOnClick">
              <PlusOutlined /> Add
            </a-button>
          </template>

          <a-spin :spinning="isSubmitting || isFetching">
            <AlertListItem
              v-for="item of alertEmails"
              :key="item.id"
              :title="item.email"
              :item="item"
              :onEdit="editEmailRecipient"
              :onDelete="deleteEmailRecipient"
            />
            <a-empty
              v-if="alertEmails.length === 0"
              :image="emptyImage"
              description="You haven't set any email recipient yet."
              :style="{ paddingBottom: 35 }"
            />
          </a-spin>
        </Card>
      </a-col>
    </a-row>
    <AlertModal
      :alerts="alerts"
      v-model:visible="alertModalVisible"
      :isCreate="isCreateAlert"
      :onSubmit="alertFormOnSubmit"
      :editAlertType="alertModalEditType"
      :isSubmitting="isSubmitting"
    />
    <EmailRecipientModal
      :emailRecipients="alertEmails"
      :isSubmitting="isSubmitting"
      v-model:visible="emailRecipientModalVisible"
      :isCreate="isCreateEmailRecipient"
      :initialValue="emailRecipientModalInitialValue"
      :onSubmit="emailRecipientFormOnSubmit"
    />
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue';
import Card from '../Card.vue';
import AlertModal from '../Modals/AlertModal.vue';
import EmailRecipientModal from '../Modals/EmailRecipientModal.vue';
import AlertListItem from '../AlertListItem.vue';
import {
  PlusOutlined,
  MailOutlined,
  BellOutlined,
} from '@ant-design/icons-vue';
import { AlertType } from '@/utils/constants';

export default {
  name: 'AlertTab',
  components: {
    Card,
    PlusOutlined,
    MailOutlined,
    BellOutlined,
    AlertModal,
    AlertListItem,
    EmailRecipientModal,
  },
  data() {
    return {
      AlertType,
      emptyImage: Empty.PRESENTED_IMAGE_SIMPLE,
      alerts: {
        batteryTrip: undefined,
        inverterTrip: undefined,
        solshareOffline: undefined,
        energyLimit: undefined,
      },
      alertSettingId: -1,
      alertEmails: [],
      alertModalVisible: false,
      isFetching: false,
      isSubmitting: false,
      isCreateAlert: true,
      alertModalEditType: undefined,
      emailRecipientModalVisible: false,
      emailRecipientModalInitialValue: undefined,
      isCreateEmailRecipient: true,
    };
  },
  mounted() {
    this.getAlertConfiguration();
  },
  methods: {
    async getAlertConfiguration() {
      this.isFetching = true;
      try {
        const { data } = await this.$http.get(
          this.$api.getAlertConfiguration(this.$store.state.selectedProject.id),
        );
        this.alertSettingId = data.alertSettingId;
        this.alerts.batteryTrip = data.batteryTrip;
        this.alerts.inverterTrip = data.inverterTrip;
        this.alerts.solshareOffline = data.solshareOffline;
        this.alerts.energyLimit = data.energyLimit;
        this.alertEmails = data.alertEmails;
      } catch (e) {
        this.$message.error('Get data unsuccessfully');
      }
      this.isFetching = false;
      this.isSubmitting = false;
    },
    editAlert(type) {
      this.isCreateAlert = false;
      this.alertModalVisible = true;
      this.alertModalEditType = type;
    },
    editEmailRecipient(email) {
      this.isCreateEmailRecipient = false;
      this.emailRecipientModalVisible = true;
      this.emailRecipientModalInitialValue = email;
    },
    async deleteAlert(type, alert) {
      this.isSubmitting = true;
      const { id } = alert;
      try {
        switch (type) {
          case AlertType.BatteryTrip:
            await this.$http.del(this.$api.updateBatteryTripAlert(id));
            break;
          case AlertType.InverterTrip:
            await this.$http.del(this.$api.updateInverterTripAlert(id));
            break;
          case AlertType.SolshareOffline:
            await this.$http.del(this.$api.updateSolshareOfflineAlert(id));
            break;
          case AlertType.EnergyLimit:
            await this.$http.del(this.$api.updateEnergyLimitAlert(id));
            break;
        }
        this.getAlertConfiguration();
        this.$message.success('Delete alert successfully');
      } catch (e) {
        this.$message.error('Delete alert unsuccessfully');
        this.isSubmitting = false;
      }
    },
    async deleteEmailRecipient(email) {
      this.isSubmitting = true;
      try {
        await this.$http.del(this.$api.updateAlertEmail(email.id));
        this.getAlertConfiguration();
        this.$message.success('Delete email recipient successfully');
      } catch (e) {
        this.$message.error('Delete email recipient unsuccessfully');
        this.isSubmitting = false;
      }
    },
    async updateAlertActivated(checked, alert, type) {
      this.isSubmitting = true;
      const { id } = alert;
      try {
        switch (type) {
          case AlertType.BatteryTrip:
            await this.$http.put(this.$api.updateBatteryTripAlert(id), {
              isActivated: checked,
            });
            break;

          case AlertType.InverterTrip:
            await this.$http.put(this.$api.updateInverterTripAlert(id), {
              isActivated: checked,
            });
            break;

          case AlertType.SolshareOffline:
            await this.$http.put(this.$api.updateSolshareOfflineAlert(id), {
              isActivated: checked,
            });
            break;

          case AlertType.EnergyLimit:
            await this.$http.put(this.$api.updateEnergyLimitAlert(id), {
              isActivated: checked,
            });
            break;
        }
        this.getAlertConfiguration();
        this.$message.success('Update alert successfully');
      } catch (e) {
        this.$message.error('Update alert unsuccessfully');
        this.isSubmitting = false;
      }
    },
    async alertFormOnSubmit(values) {
      this.isSubmitting = true;

      const { type, limit, id } = values;

      try {
        if (this.isCreateAlert) {
          switch (type) {
            case AlertType.BatteryTrip:
              await this.$http.post(this.$api.createBatteryTripAlert, {
                alertSettingId: this.alertSettingId,
              });
              break;

            case AlertType.InverterTrip:
              await this.$http.post(this.$api.createInverterTripAlert, {
                alertSettingId: this.alertSettingId,
              });
              break;

            case AlertType.SolshareOffline:
              await this.$http.post(this.$api.createSolshareOfflineAlert, {
                alertSettingId: this.alertSettingId,
                timeLength: Number(limit),
              });
              break;

            case AlertType.EnergyLimit:
              await this.$http.post(this.$api.createEnergyLimitAlert, {
                alertSettingId: this.alertSettingId,
                threshold: Number(limit),
              });
              break;
          }
        } else {
          switch (type) {
            case AlertType.SolshareOffline:
              await this.$http.put(this.$api.updateSolshareOfflineAlert(id), {
                timeLength: Number(limit),
              });
              break;

            case AlertType.EnergyLimit:
              await this.$http.put(this.$api.updateEnergyLimitAlert(id), {
                threshold: Number(limit),
              });
              break;
          }
        }
        this.$message.success(
          `${this.isCreateAlert ? 'Add' : 'Update'} alert successfully`,
        );
        this.alertModalVisible = false;
        this.getAlertConfiguration();
      } catch (e) {
        this.$message.error(
          `${this.isCreateAlert ? 'Add' : 'Update'} alert unsuccessfully`,
        );
      }
      this.isSubmitting = false;
    },
    async emailRecipientFormOnSubmit(values) {
      this.isSubmitting = true;
      const { email, id } = values;
      try {
        if (this.isCreateEmailRecipient) {
          await this.$http.post(this.$api.createAlertEmail, {
            email,
            alertSettingId: this.alertSettingId,
          });
        } else {
          await this.$http.put(this.$api.updateAlertEmail(id), {
            email,
          });
        }
        this.$message.success(
          `${
            this.isCreateEmailRecipient ? 'Add' : 'Update'
          } email recipient successfully`,
        );
        this.emailRecipientModalVisible = false;
        this.getAlertConfiguration();
      } catch (e) {
        this.$message.error(
          `${
            this.isCreateEmailRecipient ? 'Add' : 'Update'
          } email recipient unsuccessfully`,
        );
        this.isSubmitting = false;
      }
    },
    alertCreationOnClick() {
      this.isCreateAlert = true;
      this.alertModalVisible = true;
      this.alertModalEditType = undefined;
    },
    emailRecipientCreateOnClick() {
      this.isCreateEmailRecipient = true;
      this.emailRecipientModalVisible = true;
      this.emailRecipientModalInitialValue = undefined;
    },
  },
};
</script>

<style lang="less" scoped>
.alert {
  &__loader-container {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__alert-banner {
    margin-bottom: 15px;
    text-align: left;
  }
}
</style>
