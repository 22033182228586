<template>
  <div class="project-statistic-card box-shadow">
    <div class="project-statistic-card__top">
      <img
        v-if="icon"
        :src="icon"
        alt="icon"
        class="project-statistic-card__icon"
      />
      <p v-if="amount !== undefined" class="project-statistic-card__title">
        <slot name="title">{{ title }}</slot>
      </p>
      <a-spin v-else :spinning="loading">
        <p class="project-statistic-card__title">
          <slot name="title">{{ title }}</slot>
        </p>
      </a-spin>

      <a-tooltip v-if="tip" :title="tip">
        <QuestionCircleOutlined class="project-statistic-card__question-mark" />
      </a-tooltip>
    </div>
    <a-spin v-if="amount !== undefined" :spinning="loading">
      <div class="project-statistic-card__bottom">
        <h2 class="project-statistic-card__amount">
          <span>{{ prefix }}</span>
          <span ref="amount">0</span>
          <span
            v-if="amountDecimalString"
            class="project-statistic-card__decimal"
            >.{{ amountDecimalString }}</span
          >
          <span class="project-statistic-card__suffix">{{ suffix }}</span>
        </h2>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { QuestionCircleOutlined } from '@ant-design/icons-vue';
import { CountUp } from 'countup.js';

export default {
  name: 'ProjectStatisticCard',
  components: { QuestionCircleOutlined },
  props: {
    title: String,
    amount: Number,
    prefix: String,
    suffix: String,
    icon: String,
    tip: String,
    loading: Boolean,
  },
  watch: {
    amount(val) {
      const counter = new CountUp(this.$refs.amount, parseInt(val, 10));
      counter.start();
    },
  },
  computed: {
    amountDecimalString() {
      return String(this.amount).split('.')[1];
    },
  },
};
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.project-statistic-card {
  height: 100%;
  position: relative;
  background-color: #fff;
  padding: 23px 25px;
  flex: 1;
  margin: 0 10px;
  border-radius: @border-radius-base;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__title {
    margin-bottom: 0;
    color: #222;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    display: inline-block;
  }

  &__amount {
    font-size: 42px;
    //font-weight: 800;
    margin: 16px 0 0;
    line-height: 35px;
  }

  &__decimal {
    font-size: 22px;
  }

  &__suffix {
    font-size: 20px;
    margin-left: 5px;
    color: #555;
  }

  &__desc {
    color: #aeaeae;
    margin: 8px 0 0;
  }

  &__question-mark {
    position: absolute;
    right: 25px;
    top: 25px;
    color: #999;
  }

  &__icon {
    width: 25px;
    height: 25px;
  }
}

@media screen and (max-width: @mobile-width) {
  .project-statistic-card {
    &__amount {
      font-size: 27px;
    }

    &__decimal {
      font-size: 16px;
    }

    &__suffix {
      font-size: 15px;
    }
  }
}
</style>
