<template>
  <div v-if="$store.getters.getRole === 'provider'" class="provider-home">
    <a-alert
      showIcon
      type="info"
      message="Please select a project to view the details."
    />
    <ProjectTable
      :showAdd="true"
      :showLaunch="true"
      :show-subscription-usage="false"
    />
  </div>
  <PerformanceTabConsumer v-else />
</template>

<script>
import ProjectTable from '../components/ProjectTable.vue';
import PerformanceTabConsumer from '../components/Tabs/PerformanceTabConsumer.vue';

export default {
  components: { ProjectTable, PerformanceTabConsumer },
};
</script>

<style lang="less" scoped>
.provider-home {
  .ant-alert-info {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;

    .ant-alert-icon {
      color: #1890ff;
    }
  }

  &__statistic-panel {
    display: flex;
    justify-content: space-between;
  }

  &__subtitle {
    margin: 2em 0 1em;
    color: #888;
  }
}
</style>
