<template>
  <div class="perf-control-panel box-shadow">
    <h2>Welcome to performance board</h2>
    <p>
      Select 'All' or specific SolShare data over a date range and click 'View'
      to update the graph or 'Download' to download the data in CSV format.
    </p>
    <div class="perf-control-panel__control">
      <a-tooltip title="Solshare list" placement="topLeft">
        <a-select
          :loading="isFetching"
          @change="meterSelectOnChange"
          :value="meterValue"
          placeholder="Select a SolShare"
          class="perf-control-panel__select perf-control-panel__meter"
        >
          <a-select-option value="all">All</a-select-option>
          <a-select-option
            v-for="meter of [...meters].sort((a, b) => {
              if (a.alias && b.alias) {
                return a.alias.localeCompare(b.alias);
              }
              return a.serialNum.localeCompare(b.serialNum);
            })"
            :key="meter.id"
            :value="meter.id"
          >
            {{ !!meter.alias ? meter.alias : meter.serialNum }}
          </a-select-option>
        </a-select>
      </a-tooltip>
      <a-tooltip title="Tenancy list" placement="topLeft">
        <a-select
          :loading="isFetching"
          @change="propertySelectOnChange"
          :value="propertyValue"
          placeholder="Select a tenancy"
          class="perf-control-panel__select perf-control-panel__property"
        >
          <a-select-option value="all">All</a-select-option>

          <a-select-option
            v-for="property of [...properties].sort((a, b) =>
              a.address1.localeCompare(b.address1),
            )"
            :key="property.id"
            :value="property.id"
            :disabled="!property.isSnapshotEnabled"
          >
            {{ property.address1 }}
          </a-select-option>
        </a-select>
      </a-tooltip>
      <a-range-picker
        class="perf-control-panel__picker"
        :allowClear="false"
        :defaultValue="rangeDates"
        format="DD/MM/YYYY"
        :disabledDate="disabledDate"
        @change="datePickerOnChange"
      >
        <template #suffixIcon>
          <CalendarOutlined />
        </template>
      </a-range-picker>
      <a-button type="primary" class="perf-control-panel__apply" @click="apply">
        View
      </a-button>

      <a-button
        type="primary"
        class="perf-control-panel__apply"
        @click="download"
        :loading="downloadLoading"
      >
        Download
      </a-button>
    </div>
  </div>
</template>

<script setup>
import moment from 'dayjs';
import http from '../http/http';
import api from '../http/api';
import { message } from 'ant-design-vue';
import { CalendarOutlined } from '@ant-design/icons-vue';
import { normalizedDateRange } from '../utils/date';
import { useStore } from 'vuex';
import { ref } from 'vue';
const store = useStore();

const props = defineProps({
  isFetching: Boolean,
  launchDate: String,
  meters: Array,
  properties: Array,
  meterValue: String,
  meterSelectOnChange: Function,
  propertyValue: String,
  propertySelectOnChange: Function,
  consumerValue: String,
  datePickerOnChange: Function,
  rangeDates: Array,
  apply: Function,
});

function disabledDate(current) {
  return !(
    current < moment() &&
    props.launchDate &&
    current >= moment(props.launchDate).startOf('day')
  );
}

const downloadLoading = ref(false);

async function download() {
  downloadLoading.value = true;

  try {
    const [from, to, tz] = normalizedDateRange(props.rangeDates);
    const response = await http.post(
      api.downloadCSV(store.state.selectedProject.id),
      {
        projectId: store.state.selectedProject.id,
        meterId: props.meterValue,
        propertyId: props.propertyValue,
        from,
        to,
        tz,
      },
      { responseType: 'arraybuffer' },
    );

    const blob = new Blob([response.data], {
      type: response.headers['content-type'],
    });

    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `project-${store.state.selectedProject.id}_solshare-${
      props.meterValue
    }_unit-${props.propertyValue}_${props.rangeDates[0].format(
      'YYYYMMDD',
    )}-${props.rangeDates[1].format('YYYYMMDD')}.csv`;

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (e) {
    message.error('Download file failed');
  } finally {
    downloadLoading.value = false;
  }
}
</script>

<style lang="less" scoped>
@import '../assets/style/variables';

.perf-control-panel {
  background: #fff;
  padding: 25px 30px;
  margin-bottom: 20px;
  text-align: left;

  &__select {
    margin-right: 10px;
  }

  &__control {
    display: flex;
    align-items: center;
  }

  &__view-group {
    display: inline-block !important;
    width: auto;
  }

  &__meter {
    width: 300px;
  }

  &__apply {
    margin-left: 11px;
  }

  &__property {
    width: 230px;
  }

  &__resident {
    width: 200px;
  }

  &__picker {
    width: 300px;
  }
}

@media screen and (max-width: @mobile-width) {
  .perf-control-panel {
    &__control {
      flex-direction: column;
    }

    &__select {
      margin-right: 0;
      margin-bottom: 15px;
    }

    &__apply {
      margin-top: 10px;
      width: 100%;
      margin-left: 0px;
    }

    &__meter,
    &__property,
    &__resident,
    &__picker {
      width: 100%;
    }
  }
}
</style>
