<template>
  <div class="nem12-toolkit box-shadow">
    <div>Get current date range NEM12 data.</div>
    <a-button type="primary" @click="nem12Download"> Download </a-button>
  </div>
</template>

<script>
export default {
  name: 'NEM12Toolkit',
  props: {
    project: String,
    from: String,
    to: String,
  },
  methods: {
    nem12Download() {
      window.open(
        `https://nem.allumeenergy.com/${this.project}/${this.from}/${this.to}`,
        '_blank',
      );
    },
  },
};
</script>

<style scoped>
.nem12-toolkit {
  background-color: #fff;
  padding: 20px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
