<template>
  <div class="help-center__card box-shadow">
    <div class="help-center__card-icon-container">
      <img :src="icon" alt="icon" class="help-center__card-icon" />
    </div>
    <div>
      <h3 class="help-center__card-title">{{ title }}</h3>
      <p class="help-center__card-desc">{{ desc }}</p>
      <a :href="`mailto:${email}`" class="help-center__card-email">
        {{ email }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelpCard',
  props: { icon: String, title: String, desc: String, email: String },
};
</script>

<style lang="less" scoped>
.help-center {
  &__card {
    background-color: #fff;
    padding: 20px;
    display: flex;
    height: 100%;

    &-icon-container {
      width: 80px;
      margin-right: 15px;
    }

    &-icon {
      display: block;
      width: 50px;
      height: 50px;
      margin: 0 auto;
    }
  }
}
</style>
