<template>
  <div>
    <Card title="Change Email" noFooter>
      <EmailForm />
    </Card>

    <Card title="Change Password" noFooter>
      <PasswordForm />
    </Card>

    <Card title="Delete Account" noFooter>
      <DeleteAccount />
    </Card>
  </div>
</template>

<script>
import Card from '../components/Card.vue';
import EmailForm from '../components/Forms/EmailForm.vue';
import PasswordForm from '../components/Forms/PasswordForm.vue';
import DeleteAccount from '../components/DeleteAccount.vue';
export default {
  name: 'Profile',
  components: { Card, EmailForm, PasswordForm, DeleteAccount },
  data() {
    return {
      isPasswordSubmitting: false,
    };
  },
};
</script>

<style scoped></style>
